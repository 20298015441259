var gMaps = Class.extend( {
	init: function () {
		this.container = $( '.content' );
		this.gMaps = this.container.find( '#map-canvas' );
		this.json = this.container.data( 'json' );
		this.read = this.container.attr( 'data-read' );
		this.isMapReady = false;
		this.map;
		this.oMarker;
		this.markers = [];
		this.lat = null;
		this.lng = null;

		this.address = this.container.find( '.adress__adress' );
		this.addressNumber = this.address.length;

		this._initEvents();
		this._loadMap();
	},

	_initEvents: function () {

		var that = this;

		$( window ).bind( 'gMapsLoaded', function () {
			that._initializeMap( that );
		} );
	},

	_loadMap: function () {

		var that = this;


		// if google maps is loaded => Callback.
		if ( gMapsLoaded ) return window.gMapsCallback();

		// else add script google maps in head.
		var script_tag = document.createElement( 'script' );
		script_tag.setAttribute( "type", "text/javascript" );
		script_tag.setAttribute( "src", "https://maps.google.com/maps/api/js?key=AIzaSyC8RnTBrH-ylUIFlDfs0WMfYW8fbfbLIHI&callback=gMapsCallback" );
		( document.getElementsByTagName( "head" )[ 0 ] || document.documentElement ).appendChild( script_tag );

	},

	_initializeMap: function ( that ) {
		var that = this;

		// if google maps reday, set height on this.
		if ( that.isMapReady === false ) {

			that._createMap();
		}
		 
	},

	_createMap: function () {

		var that = this;

		// OPTIONS
		var mapOptions = {
			center: new google.maps.LatLng( 50.850340, 4.351710 ),
			zoom: 15,
			scrollwheel: false,
			panControl: false,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			overviewMapControl: false,
			minZoom: 1,
			maxZoom: 15
		};


		// STYLES
			var styles = [ {
				"featureType": "water",
				"elementType": "geometry.fill",
				"stylers": [ {
					"color": "#d3d3d3"
				} ]
	        }, {
				"featureType": "transit",
				"stylers": [ {
					"color": "#808080"
				}, {
					"visibility": "off"
				} ]
	        }, {
				"featureType": "road.highway",
				"elementType": "geometry.stroke",
				"stylers": [ {
					"visibility": "on"
				}, {
					"color": "#b3b3b3"
				} ]
	        }, {
				"featureType": "road.highway",
				"elementType": "geometry.fill",
				"stylers": [ {
					"color": "#ffffff"
				} ]
	        }, {
				"featureType": "road.local",
				"elementType": "geometry.fill",
				"stylers": [ {
					"visibility": "on"
				}, {
					"color": "#ffffff"
				}, {
					"weight": 1.8
				} ]
	        }, {
				"featureType": "road.local",
				"elementType": "geometry.stroke",
				"stylers": [ {
					"color": "#d7d7d7"
				} ]
	        }, {
				"featureType": "poi",
				"elementType": "geometry.fill",
				"stylers": [ {
					"visibility": "on"
				}, {
					"color": "#ebebeb"
				} ]
	        }, {
				"featureType": "administrative",
				"elementType": "geometry",
				"stylers": [ {
					"color": "#a7a7a7"
				} ]
	        }, {
				"featureType": "road.arterial",
				"elementType": "geometry.fill",
				"stylers": [ {
					"color": "#ffffff"
				} ]
	        }, {
				"featureType": "road.arterial",
				"elementType": "geometry.fill",
				"stylers": [ {
					"color": "#ffffff"
				} ]
	        }, {
				"featureType": "landscape",
				"elementType": "geometry.fill",
				"stylers": [ {
					"visibility": "on"
				}, {
					"color": "#efefef"
				} ]
	        }, {
				"featureType": "road",
				"elementType": "labels.text.fill",
				"stylers": [ {
					"color": "#696969"
				} ]
	        }, {
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [ {
					"visibility": "on"
				}, {
					"color": "#737373"
				} ]
	        }, {
				"featureType": "poi",
				"elementType": "labels.icon",
				"stylers": [ {
					"visibility": "off"
				} ]
	        }, {
				"featureType": "poi",
				"elementType": "labels",
				"stylers": [ {
					"visibility": "off"
				} ]
	        }, {
				"featureType": "road.arterial",
				"elementType": "geometry.stroke",
				"stylers": [ {
					"color": "#d6d6d6"
				} ]
	        }, {
				"featureType": "road",
				"elementType": "labels.icon",
				"stylers": [ {
					"visibility": "off"
				} ]
	        }, {}, {
				"featureType": "poi",
				"elementType": "geometry.fill",
				"stylers": [ {
					"color": "#dadada"
				} ]
			} ];


		//CREATE MAP

		that.map = new google.maps.Map( document.getElementById( 'map-canvas' ), mapOptions );

		//SET STYLES
		var styledMapType = new google.maps.StyledMapType( styles, {
			name: 'Styled'
		} );

		that.map.mapTypes.set( 'Styled', styledMapType );
		that.map.setMapTypeId( 'Styled' );

		that.isMapReady = true;
		
		// Add marker
		this.address.each(function(){
			that._addMarker( 
				$(this).attr('data-address'), 
				$(this).attr('data-lat'), 
				$(this).attr('data-lng') 
			);
		});
		

		var bounds = new google.maps.LatLngBounds();

		for ( var i = 0; i < that.markers.length; i++ ) {
			// and increase the bounds to take this point

			if ( that.markers[ i ].position.lat() && that.markers[ i ].position.lng() && that.markers[ i ].position.lat() !== null && that.markers[ i ].position.lat() !== 0 && that.markers[ i ].position.lng() !== null && that.markers[ i ].position.lng() !== 0 ) {
				if ( !isNaN( that.markers[ i ].position.lat() ) && !isNaN( that.markers[ i ].position.lng() ) ) {
					bounds.extend( new google.maps.LatLng( that.markers[ i ].position.lat(), that.markers[ i ].position.lng() ) );
				}
			}
		}

		that.map.fitBounds( bounds );

		
		this.address.click(function(){
			that.map.panTo( that.markers[ $(this).attr('data-numberItem')-1 ].getPosition() );
			that.map.setCenter( that.markers[ $(this).attr('data-numberItem')-1 ].getPosition() );
		});

		//Resize Function
		google.maps.event.addDomListener(window, "resize", function() {
			var center = that.map.getCenter();
			google.maps.event.trigger(that.map, "resize");
			that.map.setCenter(center);
		});

	},

	_addMarker: function ( address, lat, lng ) {

		var that = this;

		var place = address;
		var markerLatLng = new google.maps.LatLng( lat, lng );

		var icon_marker = that.gMaps.attr( 'data-marker-icon');

		that.oMarker = new google.maps.Marker( {
			map: that.map,
			animation: google.maps.Animation.DROP,
			position: markerLatLng,
			icon: icon_marker,
		} );

		that.markers.push( that.oMarker );

		that.map.panTo( that.oMarker.getPosition() );
	},

	_removeMarker: function () {
		
		var that = this;

		that.oMarker.setMap(null);
	}

} );

var Requests = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#my-requests' );
		this.addRequest = this.container.find( '.form--addrequest' );
		this.content = this.container.find( '.box-container' );
		this.save = this.container.find( '.btn--save-request' );

		this.popup = new PopupRequest();
		this.popup._setOptions();
		this.url = api_client_register + '/' + current_user_id;

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.addRequest.on( Event.SUBMIT, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var id = $( "#addrequest__room" ).val();

			that._addNewRoom( id );
		} );

		this.content.on( Event.CLICK, '.box', function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var room_id = $( this ).attr( 'data-room' );
			var title = $( this ).find( '.box__title' ).text();

			that.popup._setTitle( room_id, title );
			that.popup._setCurrentContent( $( this ) );
			that.popup._openPopup();
		} );

		this.save.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			that._getRequest();
		} );
	},

	_addNewRoom: function ( id ) {
		if ( id !== "" && $( '.box[data-room="' + id + '"]' ).length <= 0 ) {
			var title = this.addRequest.find( '#addrequest__room option[value="' + id + '"]' ).html();

			this.content.append( '<div class="box" data-room="' + id + '"><h3 class="box__title title-h5">' + title + '</h3><div class="col col--icon box__edit"><span class="btn btn--smaller btn--circle"><span class="icon icon-edit"></span></span></div><ul class="box__list"></ul>' );

			this.popup._setTitle( id, title );
			this.popup._openPopup();
			this.popup._addNewRow();

		} else if ( id !== "" ) {

			$( 'html, body' ).animate( {
				scrollTop: $( '.box[data-room="' + id + '"]' ).eq( 0 ).offset().top - 30
			}, 300 );

		}
	},

	_getRequest: function () {
		tinyMCE.triggerSave(); // Update tinyMCE

		var divers = $( 'textarea#request__divers' ).val();
		var rooms = $( '.box-container .box' );
		var datas = {};
		var rooms_data = {};

		rooms.each( function () {
			var room_id = $( this ).attr( 'data-room' );
			var rows = $( this ).find( 'li' );
			var room_data = [];

			rows.each( function () {
				var row = $( this );
				var weeks = [];

				row.find( '.week:checked' ).each( function () {
					weeks.push( $( this ).attr( 'data-week' ) );
				} );

				var data = {
					'todo_id': row.find( '.todos-id' ).val(),
					'todo_name': row.find( '.todos-name' ).val(),
					'weeks': weeks
				};

				room_data.push( data );
			} );

			rooms_data[ room_id ] = room_data;
		} );

		datas.divers = divers;
		datas.rooms = rooms_data;

		this._sentRequest( datas );
	},

	_sentRequest: function ( datas ) {

		$( '.box--action' ).find( '.message' ).remove();

		$.ajax( {
			type: 'POST',
			url: this.url,
			data: datas,
			success: function ( response ) {
				$( '.box--action' ).append( '<p class="message">' + response.message + '</p>' );
			},
			error: function ( status ) {}
		} );
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );

var Placeholder = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( 'body' );
		this.selector = this.container.find( 'input[data-placeholder], textarea[data-placeholder]' );
		this.element = this.selector;

		this._initEvents();
	},

	_initEvents: function () {

		var that = this;

		this.element.on( Event.FOCUS, $.proxy( that._onFocus ) );
		this.element.on( Event.CLICK, $.proxy( that._onFocus ) );
		this.element.on( Event.BLUR, $.proxy( that._onBlur ) );
	},

	_onFocus: function () {
		var el = $( this );
		var placeholder = el.attr( 'data-placeholder' );

		if ( el.val() === placeholder ) {
			el.val( '' );
			el.removeClass( 'has-placeholder' );
		}
	},

	_onBlur: function () {
		var el = $( this );
		var placeholder = el.attr( 'data-placeholder' );

		if ( el.val() === '' ) {
			el.val( placeholder );
			el.addClass( 'has-placeholder' );
		}
	},

} );

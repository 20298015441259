var Demande_de_conge = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#demande_de_conge' );
		this.addNewButton = $( '#add_ask' );
		this.sendButton = $( '.popup__send_btn' );
		this.popupContent = $( '.popup__form' );
		this.url = api_add_ask;
		this.is_run = false;

		this.popup = new PopupConge();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		// Disabled dated
		var disableddates = $( '#jours-feries' ).val().replace( /\//g, "-" ).split( ', ' );

		function DisableSpecificDates( date ) {
			var string = jQuery.datepicker.formatDate( 'dd-mm-yy', date );
			// Remove sundays
			if ( date.getDay() == 0 ) {
				return [ ( date.getDay() != 0 ), '' ];
			}

			// Remove public holidays
			return [ disableddates.indexOf( string ) == -1 ];
		}

		// Set frensh as default languages
		jQuery( function ( $ ) {
			$.datepicker.regional[ 'fr' ] = {
				closeText: 'Fermer',
				prevText: '&larr;',
				nextText: '&rarr;',
				currentText: 'Aujourd\'hui',
				monthNames: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
				'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
				monthNamesShort: [ 'Janv.', 'Févr.', 'Mars', 'Avril', 'Mai', 'Juin',
				'Juil.', 'Août', 'Sept.', 'Oct.', 'Nov.', 'Déc.' ],
				dayNames: [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ],
				dayNamesShort: [ 'Dim.', 'Lun.', 'Mar.', 'Mer.', 'Jeu.', 'Ven.', 'Sam.' ],
				dayNamesMin: [ 'D', 'L', 'M', 'M', 'J', 'V', 'S' ],
				weekHeader: 'Sem.',
				dateFormat: 'dd/mm/yy',
				firstDay: 1,
				isRTL: false,
				showMonthAfterYear: false,
				yearSuffix: ''
			};
			$.datepicker.setDefaults( $.datepicker.regional[ 'fr' ] );
		} );

		$( ".date-start" ).datepicker( {
			beforeShowDay: DisableSpecificDates
		} )
		.on( "change", function () {
			$( ".date-end" ).datepicker( "option", "minDate", getDate( this ) );
		} );

		$( ".date-end" ).datepicker( {
			beforeShowDay: DisableSpecificDates
		} )
		.on( "change", function () {
			$( ".date-start" ).datepicker( "option", "maxDate", getDate( this ) );
		} );

		function getDate( element ) {
			var date;
			try {
				date = $.datepicker.parseDate( "dd/mm/yy", element.value );
			} catch ( error ) {
				date = null;
			}

			return date;
		}

		this.addNewButton.click( function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var room_id = $( this ).attr( 'data-room' );
			var title = $( this ).find( '.box__title' ).text();

			that.popup._openPopup();
		} );

		this.sendButton.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			if ( that.is_run == false ) {
				that._getRequest();
				that.is_run = true;
			}
		} );

	},

	_getRequest: function () {

		var file = $("#justificatif")[0].files[0];

		var file_obj = null;

		if( file ) {
			var file_obj = {
				'name' : file.name,
				'extension' : file.extension,
				'size' : file.size,
				'type' : file.type,
				'data' : file.data
			};
		}

		var datas = {
			'am-id': $( '#am-id' ).val(),
			'date-start': $( '#date-start' ).val(),
			'date-end': $( '#date-end' ).val(),
			'nature-absence': $( '#nature-absence' ).val(),
			'jours-feries': $( '#jours-feries' ).val().split( ", " ),
			'justificatif' : file_obj
		};

		this._sentRequest( datas );
	},

	_sentRequest: function ( datas ) {
		var that = this;

		$.ajax( {
			type: 'POST',
			url: this.url,
			data: {
				datas: datas
			},
			success: function ( response ) {
				$( '.error__message' ).fadeOut().remove();

				if ( Array.isArray( response ) ) {
					$( '.popup__inner' ).prepend( '<p class="error__message"><i class="icon icon-close"></i>' + response[ 1 ] + '</p>' );
					var new_item = $( '.popup__inner p.error__message' ).hide().fadeIn();

				} else {
					// Add new item in the list
					$('.ask__message_success p').remove();
					if( lang == 'fr' ) {
						$('.ask__message_success').hide().append( '<p>Votre demande sera traitée dans les plus bref délais</p>' ).delay( 500 ).fadeIn();
					}
					if( lang == 'nl' ) {
						$('.ask__message_success').hide().append( '<p>Uw aanvraag wordt binnenkort verwerkt</p>' ).delay( 500 ).fadeIn();
					}
					if( lang == 'en' ) {
						$('.ask__message_success').hide().append( '<p>Your request will be processed as soon as possible</p>' ).delay( 500 ).fadeIn();
					}
					$( '.ask__list .empty_item' ).fadeOut().delay( 500 ).remove();
					$( '.ask__list' ).prepend( '<li class="ask__list__item"><p class="ask__list__item__text">' + response + '</p><div class="ask__list__item__validation"><span class="ask__validation__text">' + terms.isWait + '</span></div></li>' );
					var new_item = $( '.ask__list li:first-child' ).hide();
					that.popup._closePopup();
					new_item.delay( 500 ).slideDown();
				}
				that.is_run = false;
			},
			error: function ( status ) {}
		} );
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );

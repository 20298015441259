var Router,
	__bind = function ( fn, me ) {
		return function () {
			return fn.apply( me, arguments );
		};
	};

Router = ( function () {
	Router.CALLSTART = 'callstart';

	Router.CALLEND = 'callend';

	Router.CALLERROR = 'callerror';

	Router.INITIALIZE = 'initialize';

	Router.CLICK = 'click';

	Router.singleton;

	function Router() {
		this.backCall = __bind( this.backCall, this );
		this.cache = {};
		this.container = $( '.content' );
		this.current = this.container.attr( 'id' );
		this.headTitle = $( 'title' );
		this.pages = {
			'prev': '',
			'current': this.container.attr( 'id' )
		};
		this.requestInProgress = false;
		this.fromCache = false;
		this.fromNativeNav = false;
		this._initEvents();
		this.initCache();
		Router.singleton = this;

		// Times to wait before doing anything by pages in miliseconds
		this.pagesWaitTime = {
			default: 0,
			home: 600
		}
	}

	Router.prototype.initCache = function () {
		this.href = document.location.pathname;
		this.content = this.container;
		return this.caching();
	};

	Router.prototype._initEvents = function () {
		$( document ).on( 'click', 'a', ( function ( _this ) {
			return function ( e ) {

				var body = $( "body" ),
					container = body.find( "#container" ),
					content = container.find( ".content" ),
					heading = content.find( ".heading" ),
					headingOverlay = content.find( ".page-heading__overlay" ),
					contentSections = content.find( ".content__sections" ),
					pageHeadingText = content.find( ".page-heading__text-group" );

				_this.elm = $( e.currentTarget );

				if ( _this.elm.parent().parent().hasClass( 'menu-sidebar' ) ) {
					$( '.menu-sidebar li' ).removeClass( 'current-menu-item current_page_item' );
					_this.elm.parent().addClass( 'current-menu-item current_page_item' );
				}

				if ( $( this ).hasClass( 'btn__logout' ) ) {
					window.location.href = $( this ).attr( 'href' );
					return;
				}

				if ( $( this ).hasClass( 'lang-switcher__link' ) || $( this ).hasClass( 'sidebar__lang__switcher__link' ) ) {
					window.location.href = $( this ).attr( 'href' );
					return;
				}

				// Disable ajax
				if (
					body.hasClass( "parent-pageid-192" ) ||
					body.hasClass( "page-template-intranet" ) ||
					body.hasClass( "author" ) ||
					body.hasClass( "intranet" ) ||
					_this.elm.hasClass( "sidebar--faq__link" )

				) {
					return true;
				}

				_this.href = _this.elm.attr( 'href' );
				_this.checkRequestAvailability();

				if ( _this.isRequestAvailable ) {

					var tl = new TimelineMax();

					// Anchor click
					/* ******************************************************************************** */

					if ( content.attr( "id" ) === "home" ) {

						var homeIntroBlocks = content.find( ".who" ).find( ".section-half" ).first().find( ".section-half__item" );

						tl
							.add( "start" )
							.to( window, 1, {
								scrollTo: {
									y: 0
								},
								ease: Expo.easeInOut
							} )
							.add( "scroll" )
							.staggerTo( homeIntroBlocks, 0.7, {
								y: 200,
								ease: Elastic.easeOut.config( 1, 0.8 )
							}, 0.1, "start" )
							.to( contentSections, 1, {
								x: -15,
								autoAlpha: 0,
								ease: Expo.easeOut
							}, "start" )
							.to( ".slider", 1, {
								x: 15,
								autoAlpha: 0,
								ease: Expo.easeOut
							}, "start" )
							.to( heading, 0.5, {
								height: "460px",
								ease: Expo.easeOut
							}, "-=0.5" )

					} else {
						tl
							.add( "start" )
							.to( window, 1, {
								scrollTo: {
									y: 0
								},
								ease: Expo.easeInOut
							} )
							.add( "scroll" )
							.to( headingOverlay, 1, {
								autoAlpha: 1,
								ease: Expo.easeOut
							}, "scroll" )
							.to( contentSections, 0.6, {
								autoAlpha: 0,
								y: 15,
								ease: Expo.easeOut
							}, "scroll" )
							.to( pageHeadingText, 0.6, {
								autoAlpha: 0,
								y: -15,
								ease: Expo.easeOut
							}, "scroll" )
							// Hide 404
							.to( ".error404Button", 0.6, {
								autoAlpha: 0,
								y: -15,
								ease: Expo.easeOut
							}, 0.5 );
					}

					_this.getContent();
				}

				if ( !_this.isTargetSet ) {
					e.preventDefault();
				}

				return $( _this ).trigger( Router.CLICK );

			};
		} )( this ) );

		return $( window ).on( 'popstate', ( function ( _this ) {
			return function ( event ) {
				if ( document.location.pathname.split( '#' )[ 0 ] !== _this.href ) {
					_this.backCall();
					return event.preventDefault();
				}
			};
		} )( this ) );
	};

	Router.prototype.checkRequestAvailability = function () {
		this.isRequestAvailable = true;
		this.isTargetSet = false;
		if ( this.areUrlsMatching() ) {
			this.isRequestAvailable = false;
		}
		if ( this.requestInProgress ) {
			this.isRequestAvailable = false;
		}
		if ( this.elm.attr( 'target' ) ) {
			this.isTargetSet = true;
			return this.isRequestAvailable = false;
		}
	};

	Router.prototype.areUrlsMatching = function () {
		var currentPath, currentUrl, urlToCheck;
		urlToCheck = this.href;
		currentPath = document.location.pathname;
		currentUrl = document.location.href;

		if ( urlToCheck.substr( -1 ) === '/' ) {
			urlToCheck = urlToCheck.substr( 0, urlToCheck.length - 1 );
		}
		if ( currentUrl.substr( -1 ) === '/' ) {
			currentUrl = currentUrl.substr( 0, currentUrl.length - 1 );
			currentPath = currentPath.substr( 0, currentPath.length - 1 );
		}
		if ( urlToCheck === currentPath || urlToCheck === currentUrl ) {
			return true;
		}
		return false;
	};

	Router.prototype.backCall = function () {
		this.fromNativeNav = true;
		if ( document.location.pathname === this.href ) {
			return window.history.go( -1 );
		} else {
			this.href = document.location.pathname;
			return this.getContent();
		}
	};

	Router.prototype.getContent = function () {
		this.pages.prev = this.pages.current;
		this.requestInProgress = true;
		$( this ).trigger( Router.CALLSTART );

		if ( this.cache[ this.href ] ) {

			return setTimeout( function () {

				this.fromCache = true;

				this.content = this.cache[ this.href ][ 'content' ].clone();
				this.title = this.cache[ this.href ][ 'title' ];

				$( 'body' ).attr( 'class', this.cache[ this.href ][ 'class' ] );
				$( '.lang-switcher' ).html( this.cache[ this.href ][ 'lang' ].eq( 0 ).html() );

				$( ".menu" ).find( "li" ).removeClass( "current-menu-item current_page_item" );

				$.each( this.cache[ this.href ][ 'menu' ].find( "li" ), function ( i, elm ) {

					if ( $( elm ).hasClass( "current-menu-item" ) ) {
						$( ".menu" ).find( "li" ).eq( i ).addClass( "current-menu-item" );
					}

					if ( $( elm ).hasClass( "current_page_item" ) ) {
						$( ".menu" ).find( "li" ).eq( i ).addClass( "current_page_item" );
					}

				} );

				return this.requestSucceeded();

			}.bind( this ), this.pagesWaitTime[ this.pages.current ] || this.pagesWaitTime.default );

		} else {
			this.fromCache = false;
			return this.request();
		}

		return this.request();
	};

	Router.prototype.request = function () {
		if ( this.ajaxRequest && this.ajaxRequest !== 4 ) {
			this.ajaxRequest.abort();
		}
		return this.ajaxRequest = $.ajax( {
			url: this.href,
			success: ( function ( _this ) {
				return function ( response ) {

					_this.ajaxResponse = response;
					_this.content = $( response ).filter( '.content' );
					_this.menu = $( response ).find( '.menu' );
					_this.active = _this.menu.find( ".current-menu-item, .current_page_item" );
					_this.iFlag = 0;


					$( ".menu" ).find( "li" ).removeClass( "current-menu-item" );
					$( ".menu" ).find( "li" ).removeClass( "current_page_item" );

					$.each( _this.menu.find( "li" ), function ( i, elm ) {

						if ( $( elm ).hasClass( "current-menu-item" ) ) {
							$( ".menu" ).find( "li" ).eq( i ).addClass( "current-menu-item" );
						}

						if ( $( elm ).hasClass( "current_page_item" ) ) {
							$( ".menu" ).find( "li" ).eq( i ).addClass( "current_page_item" );
						}

					} );

					var $dom = $( document.createElement( "html" ) );
					$dom[ 0 ].innerHTML = response;
					_this.body = $dom.find( 'body' ).attr( 'class' );
					_this.lang = $dom.find( '.lang-switcher' );

					$( '.lang-switcher' ).html( _this.lang.html() );

					$( 'body' ).attr( 'class', _this.body );

					if ( _this.content.length === 0 ) {
						_this.content = $( response ).find( '.content' );
					}
					_this.title = $( response ).filter( 'title' ).text();
					return _this.requestSucceeded();
				};
			} )( this ),
			complete: ( function ( _this ) {
				return function ( request, status ) {};
			} )( this ),
			error: ( function ( _this ) {
				return function ( response ) {
					return $( _this ).trigger( Router.CALLERROR );
				};
			} )( this )
		} );
	};

	Router.prototype.requestSucceeded = function ( response ) {
		this.pages.current = this.content.attr( 'id' );
		this.changeTitle();
		this.caching();

		if ( this.fromNativeNav === false ) {
			this.changeUrl();
		}
		this.fromNativeNav = false;
		return $( this ).trigger( Router.CALLEND );
	};

	Router.prototype.changeTitle = function () {
		return this.headTitle.text( this.title );
	};

	Router.prototype.caching = function () {
		var cache = {};
		cache[ 'href' ] = this.href;
		cache[ 'content' ] = this.content.clone();
		cache[ 'header' ] = $( 'body' ).find( 'header .primary-navigation' ).clone();
		cache[ 'footer' ] = $( 'body' ).find( '#menu-footer-nav' ).clone();
		cache[ 'topnav' ] = $( 'body' ).find( '#menu-top-nav' ).clone();
		cache[ 'class' ] = $( 'body' ).attr( 'class' );
		cache[ 'id' ] = this.container.attr( 'id' );
		cache[ 'title' ] = document.title;
		cache[ 'menu' ] = $( 'body' ).find( '.menu' ).clone();
		cache[ 'lang' ] = $( 'body' ).find( '.lang-switcher' ).clone();

		return this.cache[ this.href ] = cache;
	};

	Router.prototype.changeUrl = function ( href ) {
		var pathname, state;
		if ( href ) {
			this.href = href;
		}
		state = {};
		pathname = this.href.split( window.location.host )[ 1 ];
		if ( pathname ) {
			pathname = pathname.substr( 4 );
		}
		if ( window.history.pushState ) {
			if ( this.pages.current === this.pages.prev ) {
				return window.history.replaceState( state, null, this.href );
			} else {
				return window.history.pushState( state, null, this.href );
			}
		} else {
			return window.location.hash = pathname;
		}
	};

	return Router;

} )();

var DefaultIntranet = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#default_am' );
		new NinjaFormAjax();

		this._initEvents();

		$('input[type="file"]').ezdz({
			text : terms.drop
		});
	},

	_initEvents: function () {
		var that = this;
	},

	_parseDate: function ( str ) {
		var mdy = str.split( '/' );

		return new Date( mdy[ 2 ], mdy[ 1 ] - 1, mdy[ 0 ] );
	},

	_getDays: function ( first, second ) {
		return ( this._parseDate( second ) - this._parseDate( first ) ) / ( 1000 * 60 * 60 * 24 );
	},

	_workDay: function ( start, end ) {
		var startDate = this._parseDate( start );
		var endDate = this._parseDate( end );

		var count = 0;
		var curDate = startDate;

		while ( curDate <= endDate ) {
			var dayOfWeek = curDate.getDay();
			if ( !( ( dayOfWeek == 6 ) || ( dayOfWeek == 0 ) ) )
				count++;
			curDate.setDate( curDate.getDate() + 1 );
		}
		return count;
	},

	_calcVacation: function () {
		var that = this;
		var date_start = $( '.date-start' );
		var date_end = $( '.date-end' );

		$( '.date-start, .date-end' ).on( Event.CHANGE, function () {
			if ( date_start.val() !== "" && date_end.val() !== "" ) {
				var days = that._workDay( date_start.val(), date_end.val() ); //that._getDays( date_start.val(), date_end.val() ) + 1;
				var label = terms.day;
			}
		} );
	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );

var Animate = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".content" );
		this.scrollTop = 0;
		this.animate = this.container.find( "[data-anim]" );
		this.aAnimation = new Array;

		this.animate.each( function ( elm, n ) {
				var i = $( n ).offset().top,
					r = {
						index: elm,
						top: i,
						element: $( n )
					};

				that.aAnimation.push( r );
			} ),

			this._initEvents();
		this._onMouseWheel();
	},

	_initEvents: function () {
		var that = this;

		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_onMouseWheel: function () {
		var that = this;

		this.scrollTop = $( window ).scrollTop();
		$.each( that.aAnimation, function ( elm, n ) {
			if ( that.scrollTop >= n.top - ( W.wh / 1.2 ) ) {
				n.element.addClass( "anim" );
				that.aAnimation.slice( elm );
			}
		} );
	}
} );

var PopupRequest = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( '.popup--myrequest' );
		this.popup = this.container;
		this.cache = this.container.find( '.popup__cache' );
		this.inner = this.container.find( '.popup__inner' );
		this.close = this.container.find( '.popup__cache, .popup__close' );
		this.add = this.container.find( '.popup__add-todo' );
		this.save = this.container.find( '.popup__save-todo' );
		this.iFlag = 0;
		this.options = {};

		this.isLoad = false;
		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.close.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			that._closePopup();
		} );

		this.save.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			that._savePopup();
		} );

		this.add.on( Event.CLICK, function () {
			that._addNewRow();
		} );

		this.popup.on( Event.CLICK, '.field__clear', function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			$( this ).parents( '.field' ).remove();
			that._checkAddField();
		} );

		this.container.on( Event.FOCUS, 'select.todo', function () {
			var select = $( this );
			var current_value = $( this ).val();
			var exist_options = [];
			var i = 0;

			that.container.find( 'select.todo' ).each( function () {
				exist_options.push( $( this ).val() );
			} );

			select.find( 'option' ).each( function () {
				var value = $( this ).attr( 'value' );

				if ( $.inArray( value, exist_options ) >= 0 && current_value !== value ) {
					$( this ).attr( 'disabled', 'disabled' );
				} else {
					$( this ).removeAttr( 'disabled' );
					i++;
				}
			} );
		} );

		$( document ).keyup( function ( e ) {
			if ( e.keyCode == 27 && that.container.hasClass( 'is-open' ) && !that.isLoad ) { // escape key maps to keycode `27`
				that._closePopup();
			}
		} );
	},

	/**
	 * Open popup
	 */

	_openPopup: function () {
		var that = this;

		this._checkAddField();

		if ( !this.isLoad ) {
			var tl = new TimelineMax( {
				onComplete: function () {
					that.isLoad = false;
					that.container.addClass( 'is-open' );
				}
			} );

			this.isLoad = true;

			tl
				.set( this.container, {
					display: 'block',
					autoAlpha: 1
				} )
				.set( this.cache, {
					display: 'block',
					autoAlpha: 0
				} )
				.set( this.inner, {
					display: 'block',
					autoAlpha: 0,
					x: "-50%",
					y: 120
				} )
				.to( this.cache, 0.2, {
					autoAlpha: 0.8
				} )
				.to( this.inner, 0.2, {
					autoAlpha: 1,
					x: "-50%",
					y: '-50%',
					ease: Elastic.easeOut.config( 1, 0.8 )
				}, "-=0.2" );
		}
	},

	/**
	 * Close popup
	 */

	_closePopup: function () {
		var that = this;

		if ( !this.isLoad ) {

				var tl = new TimelineMax( {
					onComplete: function () {
						that.isLoad = false;
						that.container.removeClass( 'is-open' );
						that.inner.find( 'form' ).html( '' );
					}
				} );

				this.isLoad = true;

				tl
					.to( this.inner, 0.2, {
						autoAlpha: 0,
						x: "-50%",
						y: 120,
						ease: Elastic.easeIn.config( 1, 0.8 )
					} )
					.to( this.cache, 0.2, {
						autoAlpha: 0
					}, '-=0.1' )
					.set( [ this.container, this.cache ], {
						display: 'none',
						autoAlpha: 0
					} );
		}
	},

	/**
	 * save popup
	 */

	_savePopup: function () {
		var that = this;

		if ( !this.isLoad ) {
			var isSave = this._saveRequest();

			if ( isSave ) {
				var tl = new TimelineMax( {
					onComplete: function () {
						that.isLoad = false;
						// Close
						that.container.removeClass( 'is-open' );
						that.inner.find( 'form' ).html( '' );
					}
				} );

				this.isLoad = true;

				// Close 
				tl
					.to( this.inner, 0.2, {
						autoAlpha: 0,
						x: "-50%",
						y: 120,
						ease: Elastic.easeIn.config( 1, 0.8 )
					} )
					.to( this.cache, 0.2, {
						autoAlpha: 0
					}, '-=0.1' )
					.set( [ this.container, this.cache ], {
						display: 'none',
						autoAlpha: 0
					} );

			}
		}
	},

	/**
	 * Set title and room id on popup
	 */

	_setTitle: function ( room_id, title ) {
		var that = this;

		this.container.attr( 'data-room', room_id );
		this.inner.find( '.popup__title' ).text( title );
	},

	/**
	 * Set select options
	 */

	_setOptions: function () {
		var that = this;

		$.ajax( {
			url: api_todos + '?lang=' + lang,
			success: function ( data ) {
				that.options = data;
			}
		} );
	},

	_optionsHTML: function ( selected ) {
		var that = this;
		var html = "";
		var room_id = this.container.attr( 'data-room' );

		if ( room_id !== undefined && this.options[ room_id ] !== undefined ) {
			html += '<option value="">' + terms.select_todo + '</option>';

			$.each( this.options[ room_id ], function ( key, name ) {
				var active = "";

				if ( selected !== undefined && key === selected ) {
					active = "selected";
				}

				html += '<option value="' + key + '" ' + active + '>' + name + '</option>';
			} );

		}

		return html;
	},

	/**
	 * Render checkbox for frequency
	 */

	_weekHTML: function ( elm ) {
		var that = this;
		var html = "";
		var id = this.container.attr( 'data-room' );
		var i = 0;

		html += "<ul class='listing-week'>";

		$.each( week_terms, function ( key, value ) {
			if ( key !== "week" ) {
				var label = value.slice( 0, 2 ) + '. <strong>' + value.substr( value.length - 1 ) + '</strong>';
				var checked = "";

				if ( elm !== undefined && elm.find( '.' + key ).prop( 'checked' ) ) {
					checked = 'checked';
				}


				html += '<li>';
				html += '<input type="checkbox" data-week="' + key + '" class="input--checkbox input--checkbox--hidden week ' + key + '" ' + checked + ' name="' + id + '_' + key + '_' + that.iFlag + '" id="' + id + '_' + key + '_' + that.iFlag + '">';
				html += '<label class="label--checkbox" for="' + id + '_' + key + '_' + that.iFlag + '">' + label + '</label>';
				html += '</li>';

				that.iFlag++;
			}
		} );

		html += "</ul>";

		return html;
	},

	/**
	 * Create row by current data
	 */

	_setCurrentContent: function ( elm ) {
		var that = this;
		var item = elm.find( 'li' );
		var id = elm.attr( 'data-room' );


		if ( item.length > 0 ) {
			// Generate form with current data

			$.each( item, function () {
				var field = '';
				var elm = $( this );
				var selected = elm.find( '.todos-id' ).val();
				field += '<div class="field">';
				field += '<div class="col col-left ninja__select-wrap"><button title="' + terms.clear_todo + '" class="field__clear icon icon-close"></button><select class="todo">' + that._optionsHTML( selected ) + '</select></div>';
				field += '<div class="col col-right">' + that._weekHTML( elm ) + '</div>';
				field += '</div>';

				that.inner.find( 'form' ).append( field );
			} );

		} else {
			// Add new row in form
		}
	},

	/**
	 * Add new row in form]
	 */

	_addNewRow: function () {
		var that = this;
		var field = "";

		field += '<div class="field">';
		field += '<div class="col col-left ninja__select-wrap"><button title="' + terms.clear_todo + '" class="field__clear icon icon-close"></button><select class="todo">' + that._optionsHTML() + '</select></div>';
		field += '<div class="col col-right">' + that._weekHTML() + '</div>';
		field += '</div>';

		this.inner.find( 'form' ).append( field );

		this._checkAddField();
	},

	/**
	 * Check if user can add new row
	 */

	_checkAddField: function () {
		var that = this;
		var options = [];
		var room_id = this.container.attr( 'data-room' );


		if ( room_id !== undefined && this.options[ room_id ] !== undefined ) {

			if ( this.container.find( 'select.todo' ).length >= this._countObject( this.options[ room_id ] ) ) {
				this.add.prop( 'disabled', true );
				this.add.addClass( 'is-disabled' );

				return false;
			} else {
				this.add.prop( 'disabled', false );
				this.add.removeClass( 'is-disabled' );

				return true;
			}

		} else {
			this.add.prop( 'disabled', true );
			this.add.addClass( 'is-disabled' );

			return false;
		}
	},

	_countObject: function ( obj ) {
		var count = 0;
		for ( var item in obj ) {
			if ( obj.hasOwnProperty( item ) ) {
				count++;
			}
		}

		return count;
	},

	_removeKey: function ( collection, key ) {
		// if the collections is an array
		if ( collection instanceof Array ) {
			if ( collection.indexOf( key ) != -1 ) {
				collection.splice( collection.indexOf( key ), 1 );
			}
		}
		// it's an object
		else if ( collection.hasOwnProperty( key ) ) {
			delete collection[ key ];
		}
		return collection;
	},

	/**
	 * Save request popup
	 */

	_saveRequest: function () {
		var that = this;
		var room_id = this.popup.attr( 'data-room' );
		var field = this.popup.find( '.field' );
		var is_exist = [];
		var error = false;

		// Clear room list
		$( '.box[data-room="' + room_id + '"]' ).find( 'ul li' ).remove();

		field.each( function () {
			var checkbox = $( this ).find( '.week' );
			var select = $( this ).find( '.todo' );
			var todo_id = select.val();
			var todo_name = select.find( 'option[value="' + todo_id + '"]' ).text();
			var checked = {};
			var week = {};

			if ( todo_id !== "" ) {
				// check if todo_id is exist
				if ( $.inArray( todo_id, is_exist ) < 0 ) {
					// push id in array
					is_exist.push( todo_id );

					checkbox.each( function () {
						if ( $( this ).prop( 'checked' ) ) {
							week[ $( this ).attr( 'data-week' ) ] = {
								value: week_terms[ $( this ).attr( 'data-week' ) ],
								checked: true
							};
							checked[ $( this ).attr( 'data-week' ) ] = {
								value: week_terms[ $( this ).attr( 'data-week' ) ],
								checked: true
							};
						} else {
							week[ $( this ).attr( 'data-week' ) ] = {
								value: week_terms[ $( this ).attr( 'data-week' ) ],
								checked: false
							};
						}
					} );

					$( '.box[data-room="' + room_id + '"]' ).find( 'ul' ).append( that._boxHTML( room_id, todo_name, todo_id, week, checked ) );
				} else {
					error = true;
				}
			}
		} );

		if ( $( '.box[data-room="' + room_id + '"]' ).find( 'ul li' ).length < 1 ) {
			$( '.box[data-room="' + room_id + '"]' ).remove();
		}

		return true;
	},

	/**
	 * [_boxHTML Generate list box html]
	 * @param  {[int]} room_id
	 * @param  {[string]} todo_name
	 * @param  {[int]} todo_id
	 * @param  {[object]} week      [All weeks]
	 * @param  {[object]} checked      [All weeks checked]
	 */

	_boxHTML: function ( room_id, todo_name, todo_id, week, checked ) {
		var that = this;
		var html = "";
		var week_label = "";

		if ( this._assocArraySize( checked ) > 0 ) {
			if ( this._assocArraySize( checked ) > 4 ) {
				week_label = week_terms.week;
			} else {
				var i = 0;
				$.each( checked, function ( key, label ) {
					if ( i === 0 ) {
						week_label = label.value;
					} else {
						week_label += ', ' + label.value;
					}

					i++;
				} );
			}

			html += '<li>';
			html += '<input type="text" class="input--hidden todos-id" name="' + room_id + '_' + todo_id + '_id" value="' + todo_id + '">';
			html += '<input type="text" class="input--hidden todos-name" name="' + room_id + '_' + todo_id + '_name" value="' + todo_name + '">';

			$.each( week, function ( key, label ) {
				var attr_checked = "";

				if ( label.checked ) {
					var attr_checked = "checked";
				}

				html += '<input type="checkbox" data-week="' + key + '" class="input--hidden week ' + key + '" ' + attr_checked + ' name="' + room_id + '_' + key + '">';
			} );

			html += '<p>' + todo_name + ' :</p><p>' + week_label + '</p>';
			html += '</li>';
		}

		return html;
	},

	_assocArraySize: function ( obj ) {
		var size = 0,
			key;
		for ( key in obj ) {
			if ( obj.hasOwnProperty( key ) ) size++;
		}
		return size;
	},

} );

var Transitions,
    __bind = function( fn, me ) {
        return function() {
            return fn.apply(me, arguments);
        };
    };

Transitions = (function() {
    Transitions.START = 'callstart';

    Transitions.END = 'callend';

    Transitions.MIDDLE = 'callmiddle';

    function Transitions() {
        this._defaultIn = __bind(this._defaultIn, this);
        this._defaultOut = __bind(this._defaultOut, this);
        this._transitionIn = __bind(this._transitionIn, this);
        this._transitionOut = __bind(this._transitionOut, this);
        this._onRouterEnd = __bind(this._onRouterEnd, this);
        this._onRouterStart = __bind(this._onRouterStart, this);
        this._onRouterClick = __bind(this._onRouterClick, this);
        this._initEvents = __bind(this._initEvents, this);
        this.router = new Router();
        this._transitionInDelay = 0;
        this.transitionsWhenCallFinished = true;
        this._initEvents();
    }

    Transitions.prototype._initEvents = function() {
        return $(this.router).on(Router.CLICK, this._onRouterClick).on(Router.CALLSTART, this._onRouterStart).on(Router.CALLEND, this._onRouterEnd);
    };

    Transitions.prototype._onRouterClick = function() {
    };

    Transitions.prototype._onRouterStart = function() {
        if ( this.transitionsWhenCallFinished !== true ) {
            return this._transitionOut();
        }
    };

    Transitions.prototype._onRouterEnd = function() {
        var transitionName;
        var that = this;
        if ( this.transitionsWhenCallFinished === true ) {
            transitionName = '_' + this.router.pages.prev + 'To' + this.router.pages.current.charAt(0).toUpperCase() + this.router.pages.current.slice(1);
            if ( this[ transitionName ] ) {
                return this[ transitionName ]();
            } else {
                this._transitionOut();

                var start = null;

                function customSetInterval(timestamp){
                    var progress;
                    if (start === null) start = timestamp;
                    progress = timestamp - start;
                    if ( progress < 1050 ) {
                        requestAnimationFrame(customSetInterval);
                    } else {
                        return that._transitionIn()
                    }
                }

                return window.requestAnimationFrame(customSetInterval.bind(this));

            }
        } else {
            return this._transitionIn();
        }
    };

    Transitions.prototype._transitionOut = function() {
        var transitionName;
        transitionName = '_' + this.router.pages.prev + 'Out';
        if ( this[ transitionName ] ) {
            return this[ transitionName ]();
        } else {
            return this._defaultOut();
        }
    };

    Transitions.prototype._transitionIn = function() {
        var transitionName;
        transitionName = '_' + this.router.pages.current + 'In';
        $(window).scrollTop(0);
        if ( this[ transitionName ] ) {
            return this[ transitionName ]();
        } else {
            return this._defaultIn();
        }
    };

    Transitions.prototype._defaultOut = function() {
        this.container = $('.content');
        this.router.requestInProgress = true;
        this.container.addClass('removed');
        this.container[ 0 ].offsetHeight;

        $('#container').removeClass('loaded');
        $('#container').addClass('loading');

        return $(this).trigger(Transitions.START);
    };

    Transitions.prototype._defaultIn = function() {
        var newContainer, oldContainer;
        oldContainer = $('.content');
        newContainer = this.router.content;
        oldContainer.eq(0).after(newContainer);
        oldContainer.remove();
        newContainer.addClass('added');
        newContainer[ 0 ].offsetHeight;
        newContainer.removeClass('added');
        this.sectionId = this.router.pages.current;
        $(this).trigger(Transitions.MIDDLE);
        this.router.requestInProgress = false;

        // Ajax has loaded
        /* ******************************************************************************** */

        var body = $("body"), container = body.find("#container"),
            content = container.find(".content"),
            heading = content.find(".page-heading"),
            contentSections = content.find(".content__sections"),
            pageHeadingText = content.find(".page-heading__text-group"),
            headingOverlay = content.find(".page-heading__overlay"),
            homeIntroBlocks = content.find(".who").find(".section-half").first().find(".section-half__item");

        var tl = new TimelineMax();

        if ( content.attr("id") === "home" ) {

            heading = content.find(".heading");

            tl
                .add("start")
                .set(heading, { height: "460px", opacity: 1 })

                .fromTo(contentSections, 1,
                { autoAlpha: 0, x: -15, ease: Expo.easeOut },
                { autoAlpha: 1, x: 0, ease: Expo.easeOut },
                0, "start")

                .from(".slider", 1, { y: 15, autoAlpha: 0, ease: Expo.easeOut }, 0, "start")

                .to(heading, 0.5, { height: "895px", ease: Expo.easeOut }, 0, 0.3)

                .staggerFrom(homeIntroBlocks, 0.7, {
                    y: 200,
                    ease: Elastic.easeOut.config(1, 0.8)
                }, 0.1, "start+=0.2")

        } else {

            tl
                .set(heading, { autoAlpha: 1 })
                .add("start")
                .fromTo(contentSections, 0.6,
                { autoAlpha: 0, y: 15, ease: Expo.easeOut },
                { autoAlpha: 1, y: 0, ease: Expo.easeOut },
                0, "start")
                .to(headingOverlay, 1, { autoAlpha: 0, ease: Expo.easeOut }, "start")
                .from(".page-heading__text-group", 0.6, { autoAlpha: 0, y: -15, ease: Expo.easeOut }, 0, "start");

        }

        // Remove the loading class after a while
        var timeout;
        timeout = setTimeout(function() {
            $('.loader').removeClass("is-done is-loading");
            clearTimeout(timeout);
        }, 2000);

        return $(this).trigger(Transitions.END);
    };

    return Transitions;

})();

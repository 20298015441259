var PopupConge = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( '.popup--conge' );
		this.popup = this.container;
		this.cache = this.container.find( '.popup__cache' );
		this.inner = this.container.find( '.popup__inner' );
		this.close = this.container.find( '.popup__cache, .popup__close' );

		this.isLoad = false;
		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.close.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			that._closePopup();
		} );

		$( document ).keyup( function ( e ) {
			if ( e.keyCode == 27 && that.container.hasClass( 'is-open' ) && !that.isLoad ) { // escape key maps to keycode `27`
				that._closePopup();
			}
		} );
	},

	/**
	 * Open popup
	 */

	_openPopup: function () {
		var that = this;

		$( 'input[type="file"]' ).ezdz( {
			text: terms.dropConge
		} );

		if ( !this.isLoad ) {
			var tl = new TimelineMax( {
				onComplete: function () {
					that.isLoad = false;
					that.container.addClass( 'is-open' );
				}
			} );

			this.isLoad = true;

			tl
				.set( this.container, {
					display: 'block',
					autoAlpha: 1
				} )
				.set( this.cache, {
					display: 'block',
					autoAlpha: 0
				} )
				.set( this.inner, {
					display: 'block',
					autoAlpha: 0,
					x: "-50%",
					y: 120
				} )
				.to( this.cache, 0.2, {
					autoAlpha: 0.8
				} )
				.to( this.inner, 0.2, {
					autoAlpha: 1,
					x: "-50%",
					y: '-50%',
					ease: Elastic.easeOut.config( 1, 0.8 )
				}, "-=0.2" );
		}
	},

	/**
	 * Close popup
	 */

	_closePopup: function () {
		var that = this;

		if ( !this.isLoad ) {

				var tl = new TimelineMax( {
					onComplete: function () {
						that.isLoad = false;
						that.container.removeClass( 'is-open' );
						that.inner.find( '.field__input' ).val( '' );
						$( '.error__message' ).fadeOut().remove();
						$( ".date-start" ).datepicker( "option", "maxDate", null);
						$( ".date-end" ).datepicker( "option", "minDate", null);
						$( '#ui-datepicker-div' ).hide();
						$('[type="file"]').ezdz('destroy');
					}
				} );

				this.isLoad = true;

				tl
					.to( this.inner, 0.2, {
						autoAlpha: 0,
						x: "-50%",
						y: 120,
						ease: Elastic.easeIn.config( 1, 0.8 )
					} )
					.to( this.cache, 0.2, {
						autoAlpha: 0
					}, '-=0.1' )
					.set( [ this.container, this.cache ], {
						display: 'none',
						autoAlpha: 0
					} );
		}
	},

} );

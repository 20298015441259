var App, W, Pages, H, MediaPlayer, MediaPlayer_Controls, MediaPlayer_Flash, MediaPlayer_HTML, MediaPlayer_Proxy, MediaPlayer_Templates, MediaPlayer_Video, UI_Slider, sounds, gMapsLoaded = false;
var $ = jQuery;

App = ( function () {
	function App() {

		this.update = __bind( this.update, this );
		this._onResize = __bind( this._onResize, this );
		this._onTransitionsMiddle = __bind( this._onTransitionsMiddle, this );
		this._initSection = __bind( this._initSection, this );
		this._initEvents = __bind( this._initEvents, this );
		W.init();

		this.transitions = new TransitionsFactory();
		this.header = new Header();
		this.footer = new Footer();
		this._initEvents();
		this._initSection();
	}

	App.prototype._initEvents = function () {
		W.body.on( Event.MOUSEDOWN, this._onMouseDown ).on( Event.MOUSEMOVE, this._onMouseMove ).on( Event.MOUSEUP, this._onMouseUp ).on( Event.WHEEL, this._onMouseWheel ).on( Event.KEYDOWN, this._onKeyDown );
		W.window.on( 'resize', this._onResize );
		return $( this.transitions ).on( Transitions.MIDDLE, this._onTransitionsMiddle );
	};

	App.prototype._initSection = function () {

		this._destroySection();

		this.sectionId = $( '.content' ).attr( 'id' );

		switch ( this.sectionId ) {
			case 'home':
				this.section = new Home();
				break;
			case 'who':
				this.section = new Who();
				break;
			case 'how':
				this.section = new How();
				break;
			case 'contact':
				this.section = new Contact();
				break;
			case 'calculator':
				this.section = new Calculator();
				break;
			case 'faq':
				this.section = new Faq();
				break;
			case 'my-requests':
				this.section = new Requests();
				break;
			case 'default_am':
				new DefaultIntranet();
				break;
			case 'default_am_client':
				new DefaultIntranet();
				break;
			case 'error404':
				this.section = new Error404();
				break;
			case 'jobs':
				this.section = new Jobs();
				break;
			case 'my_account':
				this.section = new Account();
				break;
			case 'demande_de_conge':
				this.section = new Demande_de_conge();
				break;
            case 'intranet':
                this.section = new Intranet();
                break;
		}

        // toggle class for all the ninjaforms checkbox and radio input
        $('.ninja-forms-cont ul input[type=radio], .ninja-forms-cont ul input[type=checkbox]').change( function(){
            $(this).parent().parent().parent().find('label').removeClass('checked'); // remove class from other radio
            $(this).parent().addClass( 'checked' );
        } );

		gotoHash();
	};

	App.prototype._destroySection = function () {
		if ( this.section && this.section.destroy ) {
			return this.section.destroy();
		}
	};

	App.prototype._onTransitionsMiddle = function () {
		return this._initSection();
	};

	App.prototype._onResize = function () {
		W.sw = screen.width;
		W.sh = screen.height;
		W.ww = W.window.width();
		W.wh = W.window.height();

		if ( this.section && this.section.resize ) {
			this.section.resize();
		}

		if ( this.header && this.header.resize ) {
			this.header.resize();
		}

	};

	App.prototype.update = function () {
		if ( this.section && this.section.update ) {
			return this.section.update();
		}
	};

	return App;

} )();

window.gMapsCallback = function () {
	gMapsLoaded = true;
	$( window ).trigger( 'gMapsLoaded' );
}

function gotoHash() {
	if ( location.hash ) {
		var hash = location.hash;
		var ddh = $( document ).height();

		if ( $( hash ).length ) {

			var hashOffset = $( hash ).offset().top;

			if ( hashOffset > ( ddh - W.wh ) ) {
				hashOffset = hashOffset - ( W.wh / 1.4 );
			}

			$( 'html,body' ).animate( {
				scrollTop: hashOffset
			}, 2000, 'easeInOutExpo' );
		}
	}
};

$( function () {
	var app, tick;
	app = new App();

	return ( tick = function () {
		app.update();
		return window.requestAnimationFrame( tick );
	} )();
} );

var EmailSpam = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( 'body' );
		this.email = this.container.find( '[data-email]' );
		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.email.each( function ( index ) {
			var name = $( this ).attr( 'data-email' );
			var host = $( this ).attr( 'data-domain' );
			var email = 'mailto:' + name + '@' + host;

			$( this ).attr( 'href', email );

			if ( $( this ).hasClass( 'is-show' ) ) {
				$( this ).html( name + '@' + host );
			}
		} );

	},


} );

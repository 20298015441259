var Event;
var $ = jQuery;

Event = ( function () {
	function Event() {}

	Event.MOUSEDOWN = $( 'body' ).hasClass( 'tablet' ) ? 'touchstart' : 'mousedown';

	Event.MOUSEUP = $( 'body' ).hasClass( 'tablet' ) ? 'touchend' : 'mouseup';

	Event.MOUSEMOVE = $( 'body' ).hasClass( 'tablet' ) ? 'touchmove' : 'mousemove';

	Event.CLICK = $( 'body' ).hasClass( 'tablet' ) ? 'touchstart' : 'click';

	Event.ENTER = $( 'body' ).hasClass( 'tablet' ) ? 'touchstart' : 'mouseenter';

	Event.LEAVE = $( 'body' ).hasClass( 'tablet' ) ? 'touchend' : 'mouseleave';

	Event.KEYDOWN = 'keydown';

	Event.WHEEL = 'mousewheel';

	Event.LOADED = 'loaded';

	Event.STEPS = 'steps';

	Event.SUBMIT = 'submit';

	Event.PROGRESS = 'progress';

	Event.SCROLL = 'scroll';

	Event.RESIZE = 'resize';

	Event.BLUR = 'blur';

	Event.FOCUS = 'focus';

	Event.CHANGE = 'change';

	return Event;
} )();

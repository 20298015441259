var CustomFAQLinks = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( 'body' );
		this.links = this.container.find( 'a[href*="faqs"]' );

		if ( this.links.length > 0 ) {
			this._initEvents();
		}
	},

	_initEvents: function () {
		var that = this;

		$.each( this.links, function () {
			var href = $( this ).attr( 'href' );
			var faq = href.split( '/' ).filter( function ( v ) {
				return v !== ''
			} );

			$( this ).attr( 'href', faq_link + '?faq=' + faq[ faq.length - 1 ] );
		} );

	}
} );

var SetFullSize = Class.extend( {

	init: function ( options ) {

		var that = this;

		this.element = options.element;
		this.parent = options.parent;
		this._initEvents();
	},

	_initEvents: function () {

		this.element.removeAttr( "style" );

		var iWidth,
			iHeight,
			iRatio,
			parentHeight,
			parentWidth;

		if ( !this.parent ) {

			parentHeight = W.wh;
			parentWidth = W.ww;


		} else {

			parentHeight = this.parent.height();
			parentWidth = this.parent.width();
		}



		iWidth = this.element.width();
		iHeight = this.element.height();

		iRatio = iHeight / iWidth;

		if ( iRatio < parentHeight / parentWidth ) {

			var iNewWidth = parentHeight / iRatio;

			this.element.css( {
				height: parentHeight,
				width: iNewWidth,
				'margin': '0 0 0 -' + ( ( iNewWidth - parentWidth ) / 2 ) + 'px'
			} );

		} else {

			var iNewHeight = parentWidth * iRatio;
			this.element.css( {
				height: iNewHeight,
				width: parentWidth,
				'margin': '-' + ( ( iNewHeight - parentHeight ) / 2 ) + 'px 0 0 0'
			} );

		}

	}


} );

var Who = Class.extend({

    init: function() {

        var that = this, interval;

        this.container = $('#who');
        this.circle = this.container.find('.circles');
        this.circleItem = this.circle.find('.circles__item');
        if ( device == "desktop" ) {
            this._initCircle();
        }

        new Slider__who__values();
        this._initEvents();
        this._activeItem(0);

    },

    _initEvents: function() {
        var that = this;

        this.circleItem.on(Event.CLICK, function( e ) {
            e.preventDefault();
            e.stopPropagation();
            var index = that.circleItem.index($(this));
            console.log(index);
            that._activeItem(index);
        });

    },

    _activeItem: function( index ) {
        var that = this;
        $(".circles__content")
            .siblings().removeClass("is-active")
            .eq(index).addClass('is-active');
        $(".circles__item")
            .siblings().removeClass("is-active").eq(index).addClass('is-active');
    },

    _initCircle: function() {
        var that = this;
        var angle = 4.72;
        var step = (2 * Math.PI) / this.circleItem.length;
        var width = this.circle.width();
        var height = this.circle.height();

        this.circleItem.each(function() {
            var currentCircle = $(this);
            var x = Math.round(width / 2 + width / 2 * Math.cos(angle) - currentCircle.width() / 2);
            var y = Math.round(height / 2 + height / 2 * Math.sin(angle) - currentCircle.height() / 2);

            currentCircle.css({
                left: x + 'px',
                top: y + 'px'
            });
            angle += step;

            // Add left/right classes for placement purposes
            if ( currentCircle.offset().left > ($(window).outerWidth(true) / 2) ) {
                currentCircle.addClass("right");
            } else {
                currentCircle.addClass("left");
            }

        });


        var circleItem = $(".circles__item"), circleLength = circleItem.length;

        // Add top class
        circleItem.eq(0).addClass("top");

        // Add bottom class
        if ( !(circleLength % 2) ) {
            circleItem.eq(circleLength / 2).addClass("bottom");
        }

        this.circle.removeClass("is-hidden");

    },

    resize: function() {
        var that = this;
    },

    destroy: function() {

    }

});

MediaPlayer = (function() {
  function MediaPlayer(options) {
    this._onPlayerLeave = __bind(this._onPlayerLeave, this);
    this._onPlayerEnter = __bind(this._onPlayerEnter, this);
    this._onPlayerPaused = __bind(this._onPlayerPaused, this);
    this._onPlayerEnded = __bind(this._onPlayerEnded, this);
    this._onPlayerPlaying = __bind(this._onPlayerPlaying, this);
    this._onPlayerWaiting = __bind(this._onPlayerWaiting, this);
    this._onPlayerTimeUpdate = __bind(this._onPlayerTimeUpdate, this);
    this._onPlayerProgress = __bind(this._onPlayerProgress, this);
    this._onPlayerCanPlay = __bind(this._onPlayerCanPlay, this);
    this._onControlsVolumeChange = __bind(this._onControlsVolumeChange, this);
    this._onControlsSeekEnd = __bind(this._onControlsSeekEnd, this);
    this._onControlsSeekStart = __bind(this._onControlsSeekStart, this);
    this._onControlsVolume = __bind(this._onControlsVolume, this);
    this._onControlsFullscreen = __bind(this._onControlsFullscreen, this);
    this._onControlsStop = __bind(this._onControlsStop, this);
    this._onControlsPause = __bind(this._onControlsPause, this);
    this._onControlsPlay = __bind(this._onControlsPlay, this);
    this.leaveFullscreen = __bind(this.leaveFullscreen, this);
    this.enterFullscreen = __bind(this.enterFullscreen, this);
    this.hideControls = __bind(this.hideControls, this);
    this.showControls = __bind(this.showControls, this);
    this.setSrc = __bind(this.setSrc, this);
    this.seek = __bind(this.seek, this);
    this.setVolume = __bind(this.setVolume, this);
    this.getBuffer = __bind(this.getBuffer, this);
    this.getSrc = __bind(this.getSrc, this);
    this.getState = __bind(this.getState, this);
    this.getDuration = __bind(this.getDuration, this);
    this.getCurrentTime = __bind(this.getCurrentTime, this);
    this.unmute = __bind(this.unmute, this);
    this.mute = __bind(this.mute, this);
    this.stop = __bind(this.stop, this);
    this.pause = __bind(this.pause, this);
    this.play = __bind(this.play, this);
    this.container = options.container, this.src = options.src, this.poster = options.poster, this.autoplay = options.autoplay, this.loop = options.loop, this.hideControlsAllowed = options.hideControlsAllowed, this.type = options.type;
    this.isMuted = false;
    this.state = -1;
    this.tempState = null;
    this.canPlay = false;
    this.queueSeek = null;
    this.bufferingInterval = null;
    this.isBuffering = false;
    this.isPaused = false;
    this.isFullscreen = false;

    this._initPlayer();
  }

  MediaPlayer.prototype._initPlayer = function() {
    this.container.addClass('stopped');
    if (this.type === 'audio') {
      this.player = new MediaPlayer_Audio({
        container: this.container,
        src: this.container.attr('data-src') || this.src
      });
    } else {
      this.player = new MediaPlayer_Video({
        container: this.container,
        src: this.container.attr('data-src') || this.src
      });
    }
    this.media = this.player.$media;
    this.mediaContainer = this.player.mediaContainer;
    this.controls = new MediaPlayer_Controls({
      container: this.container,
      poster: this.container.attr('data-poster') || this.poster,
      autoplay: this.autoplay,
      hideControlsAllowed: this.hideControlsAllowed
    });
    this._initListeners();
    if (this.autoplay) {
      return this.play();
    }
  };

  MediaPlayer.prototype._initListeners = function() {
    $(this.controls).on(MediaPlayer_Controls.PLAY, this._onControlsPlay).on(MediaPlayer_Controls.PAUSE, this._onControlsPause).on(MediaPlayer_Controls.STOP, this._onControlsStop).on(MediaPlayer_Controls.FULLSCREEN, this._onControlsFullscreen).on(MediaPlayer_Controls.VOLUME, this._onControlsVolume).on(MediaPlayer_Controls.SEEK_START, this._onControlsSeekStart).on(MediaPlayer_Controls.SEEK_END, this._onControlsSeekEnd).on(MediaPlayer_Controls.VOLUME_CHANGE, this._onControlsVolumeChange);
    $(this.player).on(MediaPlayer.CANPLAY, this._onPlayerCanPlay).on(MediaPlayer.PROGRESS, this._onPlayerProgress).on(MediaPlayer.TIMEUPDATE, this._onPlayerTimeUpdate).on(MediaPlayer.WAITING, this._onPlayerWaiting).on(MediaPlayer.PLAYING, this._onPlayerPlaying).on(MediaPlayer.ENDED, this._onPlayerEnded).on(MediaPlayer.PAUSED, this._onPlayerPaused);
    return this.container.on(MediaPlayer.ENTER, this._onPlayerEnter).on(MediaPlayer.LEAVE, this._onPlayerLeave);
  };

  MediaPlayer.playerState = {
    ENDED: 1,
    PLAYING: 2,
    PAUSED: 3,
    BUFFERING: 4,
    CUED: 5
  };

  MediaPlayer.CANPLAY = 'canplay';

  MediaPlayer.PROGRESS = 'progress';

  MediaPlayer.TIMEUPDATE = 'timeupdate';

  MediaPlayer.WAITING = 'waiting';

  MediaPlayer.PLAYING = 'playing';

  MediaPlayer.SEEKED = 'seeked';

  MediaPlayer.BUFFERING = 'buffering';

  MediaPlayer.ENDED = 'ended';

  MediaPlayer.PAUSED = 'paused';

  MediaPlayer.MUTE = 'onmute';

  MediaPlayer.UNMUTE = 'onunmute';

  MediaPlayer.ENTER = 'mouseenter';

  MediaPlayer.LEAVE = 'mouseleave';

  MediaPlayer.prototype.play = function() {
    this.isPaused = false;
    this.state = MediaPlayer.playerState.PLAYING;
    this.player.play();
    return this.controls.onPlay();
  };

  MediaPlayer.prototype.pause = function() {
    this.isPaused = true;
    this.state = MediaPlayer.playerState.PAUSED;
    this.player.pause();
    return this.controls.onPause();
  };

  MediaPlayer.prototype.stop = function() {
    this.state = MediaPlayer.playerState.ENDED;
    this.player.stop();
    this.controls.onStop();
    if (this.loop === true) {
      return this.play();
    }
  };

  MediaPlayer.prototype.mute = function() {
    this.isMuted = true;
    this.player.mute();
    this.controls.onMute();
    return $(this).trigger(MediaPlayer.MUTE);
  };

  MediaPlayer.prototype.unmute = function() {
    this.isMuted = false;
    this.player.unmute();
    this.controls.onUnMute();
    return $(this).trigger(MediaPlayer.UNMUTE);
  };

  MediaPlayer.prototype.getCurrentTime = function() {
    return this.player.getCurrentTime();
  };

  MediaPlayer.prototype.getDuration = function() {
    return this.player.getDuration();
  };

  MediaPlayer.prototype.getState = function() {
    return this.state;
  };

  MediaPlayer.prototype.getSrc = function() {
    return this.player.getSrc();
  };

  MediaPlayer.prototype.getBuffer = function() {
    return this.player.getBuffer();
  };

  MediaPlayer.prototype.setVolume = function(val) {
    return this.player.setVolume(val);
  };

  MediaPlayer.prototype.seek = function(val) {
    if (this.canPlay === false) {
      return this.queueSeek = val;
    } else {
      this.player.seek(val);
      this.controls.onSeek(val / this.player.getDuration());
      return $(this).trigger(MediaPlayer.SEEKED);
    }
  };

  MediaPlayer.prototype.setSrc = function(src) {
    this.canPlay = false;
    return this.player.setSrc(src);
  };

  MediaPlayer.prototype.showControls = function() {
    return this.controls.show();
  };

  MediaPlayer.prototype.hideControls = function() {
    return this.controls.hide();
  };

  MediaPlayer.prototype.enterFullscreen = function() {
    var fn;
    fn = ['requestFullscreen', 'msRequestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullscreen'];
    $.each(fn, (function(_this) {
      return function(key, f) {
        if (_this.container[0][f]) {
          _this.container[0][f]();
          return !1;
        }
      };
    })(this));
    this.isFullscreen = true;
    return this.container.addClass('fullscreen');
  };

  MediaPlayer.prototype.leaveFullscreen = function() {
    var fn;
    fn = ['exitFullscreen', 'msExitFullscreen', 'mozCancelFullScreen', 'webkitExitFullscreen'];
    $.each(fn, (function(_this) {
      return function(key, f) {
        if (document[f]) {
          document[f]();
          return !1;
        }
      };
    })(this));
    this.isFullscreen = false;
    return this.container.removeClass('fullscreen');
  };

  MediaPlayer.prototype._onControlsPlay = function() {
    return this.play();
  };

  MediaPlayer.prototype._onControlsPause = function() {
    return this.pause();
  };

  MediaPlayer.prototype._onControlsStop = function() {
    this.pause();
    return this.seek(0);
  };

  MediaPlayer.prototype._onControlsFullscreen = function() {
    if (this.isFullscreen === true) {
      return this.leaveFullscreen();
    } else {
      return this.enterFullscreen();
    }
  };

  MediaPlayer.prototype._onControlsVolume = function() {
    if (this.isMuted === true) {
      return this.unmute();
    } else {
      return this.mute();
    }
  };

  MediaPlayer.prototype._onControlsSeekStart = function() {
    this.tempState = this.state;
    return this.pause();
  };

  MediaPlayer.prototype._onControlsSeekEnd = function(e, perc) {
    this.seek(perc * this.getDuration());
    if (this.tempState === MediaPlayer.playerState.PLAYING) {
      return this.play();
    }
  };

  MediaPlayer.prototype._onControlsVolumeChange = function(e, val) {
    return this.setVolume(val);
  };

  MediaPlayer.prototype._onPlayerCanPlay = function() {
    this.canPlay = true;
    if (this.queueSeek !== null) {
      this.seek(this.queueSeek);
      this.queueSeek = null;
    }
    this.controls.setTotalTime(this.getDuration());
    return $(this).trigger(MediaPlayer.CANPLAY);
  };

  MediaPlayer.prototype._onPlayerProgress = function() {
    this.controls.onProgress(this.getBuffer() / this.getDuration());
    return $(this).trigger(MediaPlayer.PROGRESS);
  };

  MediaPlayer.prototype._onPlayerTimeUpdate = function() {
    $(this).trigger(MediaPlayer.TIMEUPDATE);
    if (this.state === MediaPlayer.playerState.PLAYING) {
      return this.controls.onUpdate(this.getCurrentTime() / this.getDuration());
    }

    /*clearTimeout(@bufferingInterval)
        @bufferingInterval = setTimeout () =>
          if @state != MediaPlayer.playerState.PAUSED
            @state = MediaPlayer.playerState.BUFFERING
            @isBuffering = true
            $(@).trigger(MediaPlayer.BUFFERING)
        , 500
     */
  };

  MediaPlayer.prototype._onPlayerWaiting = function() {
    return $(this).trigger(MediaPlayer.WAITING);
  };

  MediaPlayer.prototype._onPlayerPlaying = function() {
    this.isBuffering = false;
    return $(this).trigger(MediaPlayer.PLAYING);
  };

  MediaPlayer.prototype._onPlayerEnded = function() {
    this.stop();
    return $(this).trigger(MediaPlayer.ENDED);
  };

  MediaPlayer.prototype._onPlayerPaused = function() {
    return $(this).trigger(MediaPlayer.PAUSED);
  };

  MediaPlayer.prototype._onPlayerEnter = function() {
    return this.container.addClass('hover');
  };

  MediaPlayer.prototype._onPlayerLeave = function() {
    return this.container.removeClass('hover');
  };

  return MediaPlayer;

})();


/*class MediaPlayer_Audio extends MediaPlayer_HTML

  constructor: (options) ->

    @html = """
          <div class="media-container">
            <audio>
            </audio>
          </div>
        """

    super




   * -------------------------------------------------------------o Setters

  setSrc: (src) =>

    sources = """
          <source src="#{src}" type="audio/mpeg" />
        """
        
    @$media.html(sources)
    @media.load()
 */



MediaPlayer_Controls = (function() {
  function MediaPlayer_Controls(options) {
    this.setVolume = __bind(this.setVolume, this);
    this.setTotalTime = __bind(this.setTotalTime, this);
    this.show = __bind(this.show, this);
    this.hide = __bind(this.hide, this);
    this.onUnMute = __bind(this.onUnMute, this);
    this.onMute = __bind(this.onMute, this);
    this.onSeek = __bind(this.onSeek, this);
    this.onStop = __bind(this.onStop, this);
    this.onProgress = __bind(this.onProgress, this);
    this.onUpdate = __bind(this.onUpdate, this);
    this.onBuffering = __bind(this.onBuffering, this);
    this.onPause = __bind(this.onPause, this);
    this.onPlay = __bind(this.onPlay, this);
    this._sortTime = __bind(this._sortTime, this);
    this._changeTime = __bind(this._changeTime, this);
    this._onMouseMove = __bind(this._onMouseMove, this);
    this._onVolumeBarEnd = __bind(this._onVolumeBarEnd, this);
    this._onVolumeBarChange = __bind(this._onVolumeBarChange, this);
    this._onProgressBarEnd = __bind(this._onProgressBarEnd, this);
    this._onProgressBarChange = __bind(this._onProgressBarChange, this);
    this._onProgressBarStart = __bind(this._onProgressBarStart, this);
    this._onVolumeButtonClick = __bind(this._onVolumeButtonClick, this);
    this._onFullscreenButtonClick = __bind(this._onFullscreenButtonClick, this);
    this._onStopButtonClick = __bind(this._onStopButtonClick, this);
    this._onPauseButtonClick = __bind(this._onPauseButtonClick, this);
    this._onPlayButtonClick = __bind(this._onPlayButtonClick, this);
    this.container = options.container, this.poster = options.poster, this.autoplay = options.autoplay, this.hideControlsAllowed = options.hideControlsAllowed;
    this._areControlsHidden = false;
    this._isMuted = false;
    this._initInterface();
    this._initEvents();
  }

  MediaPlayer_Controls.PLAY = 'play';

  MediaPlayer_Controls.PAUSE = 'pause';

  MediaPlayer_Controls.STOP = 'stop';

  MediaPlayer_Controls.FULLSCREEN = 'fullscreen';

  MediaPlayer_Controls.VOLUME = 'volume';

  MediaPlayer_Controls.SEEK_START = 'seek_start';

  MediaPlayer_Controls.SEEK_END = 'seek_end';

  MediaPlayer_Controls.VOLUME_CHANGE = 'volume_change';

  MediaPlayer_Controls.prototype._initInterface = function() {
    this.controls = $(MediaPlayer_Templates.view);
    this.container.append(this.controls);
    if (this.autoplay !== true && this.poster) {
      this.poster = $("<img src=\"" + this.poster + "\" class=\"poster\" />");
      this.container.append(this.poster);
    }
    this.playButton = this.container.find('.play-button');
    this.pauseButton = this.container.find('.pause-button');
    this.stopButton = this.container.find('.stop-button');
    this.fullscreenButton = this.container.find('.fullscreen-button');
    this.volumeButton = this.container.find('.volume-button');
    this.volumeContainer = this.container.find('.volume-container');
    this.volumeProgress = this.volumeContainer.find('.progress');
    this.volumeThumb = this.volumeContainer.find('.thumb');
    this.timelineContainer = this.container.find('.timeline-container');
    this.timelineBuffer = this.timelineContainer.children('.buffer');
    this.timelineProgress = this.timelineContainer.children('.progress');
    this.timeContainer = this.container.find('.time-container');
    this.timeCurrent = this.timeContainer.find('.time-current');
    this.timeTotal = this.timeContainer.find('.time-total');
    this.progressBar = new UI_Slider({
      container: this.timelineContainer,
      type: 'horizontal'
    });
    return this.volumeBar = new UI_Slider({
      container: this.volumeContainer,
      type: 'vertical',
      startValue: 1
    });
  };

  MediaPlayer_Controls.prototype._initEvents = function() {
    this.playButton.on(Event.CLICK, this._onPlayButtonClick);
    this.pauseButton.on(Event.CLICK, this._onPauseButtonClick);
    this.stopButton.on(Event.CLICK, this._onStopButtonClick);
    this.fullscreenButton.on(Event.CLICK, this._onFullscreenButtonClick);
    this.volumeButton.on(Event.CLICK, this._onVolumeButtonClick);
    $(this.progressBar).on(UI_Slider.START, this._onProgressBarStart).on(UI_Slider.CHANGE, this._onProgressBarChange).on(UI_Slider.END, this._onProgressBarEnd);
    $(this.volumeBar).on(UI_Slider.START, this._onVolumeBarChange).on(UI_Slider.CHANGE, this._onVolumeBarChange);
    if (this.hideControlsAllowed) {
      return this.container.on(Event.MOUSEMOVE, this._onMouseMove);
    }
  };


  MediaPlayer_Controls.prototype._onPlayButtonClick = function() {
    return $(this).trigger(MediaPlayer_Controls.PLAY);
  };

  MediaPlayer_Controls.prototype._onPauseButtonClick = function() {
    return $(this).trigger(MediaPlayer_Controls.PAUSE);
  };

  MediaPlayer_Controls.prototype._onStopButtonClick = function() {
    return $(this).trigger(MediaPlayer_Controls.STOP);
  };

  MediaPlayer_Controls.prototype._onFullscreenButtonClick = function() {
    return $(this).trigger(MediaPlayer_Controls.FULLSCREEN);
  };

  MediaPlayer_Controls.prototype._onVolumeButtonClick = function() {
    return $(this).trigger(MediaPlayer_Controls.VOLUME);
  };

  MediaPlayer_Controls.prototype._onProgressBarStart = function() {
    $(this).trigger(MediaPlayer_Controls.SEEK_START);
    return this.timelineProgress.css({
      'width': this.progressBar.perc * this.timelineContainer.width()
    });
  };

  MediaPlayer_Controls.prototype._onProgressBarChange = function() {
    return this.timelineProgress.css({
      'width': this.progressBar.perc * this.timelineContainer.width()
    });
  };

  MediaPlayer_Controls.prototype._onProgressBarEnd = function() {
    return $(this).trigger(MediaPlayer_Controls.SEEK_END, [this.progressBar.perc]);
  };

  MediaPlayer_Controls.prototype._onVolumeBarChange = function() {
    $(this).trigger(MediaPlayer_Controls.VOLUME_CHANGE, [this.volumeBar.perc]);
    return this.setVolume(this.volumeBar.perc);
  };

  MediaPlayer_Controls.prototype._onVolumeBarEnd = function() {};

  MediaPlayer_Controls.prototype._onMouseMove = function() {
    this.show();
    clearTimeout(this._moveTimer);
    return this._moveTimer = setTimeout((function(_this) {
      return function() {
        return _this.hide();
      };
    })(this), this._hideControlsDelay);
  };

  MediaPlayer_Controls.prototype._changeTime = function(perc) {
    var newTime;
    newTime = this._sortTime(perc * this.duration);
    if (newTime !== this.timeText) {
      this.timeText = newTime;
      return this.timeCurrent.text(newTime);
    }
  };

  MediaPlayer_Controls.prototype._sortTime = function(time) {
    var m, s;
    m = ~~(time / 60);
    s = ~~(time - m * 60);
    return (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s);
  };

  MediaPlayer_Controls.prototype.onPlay = function() {
    return this.container.addClass('playing').removeClass('paused stopped');
  };

  MediaPlayer_Controls.prototype.onPause = function() {
    return this.container.addClass('paused').removeClass('playing stopped');
  };

  MediaPlayer_Controls.prototype.onBuffering = function() {};

  MediaPlayer_Controls.prototype.onUpdate = function(perc) {
    this.timelineProgress.css({
      'width': perc * this.timelineContainer.width()
    });
    return this._changeTime(perc);
  };

  MediaPlayer_Controls.prototype.onProgress = function(perc) {
    return this.timelineBuffer.css({
      'width': perc * this.timelineContainer.width()
    });
  };

  MediaPlayer_Controls.prototype.onStop = function() {
    this.container.addClass('stopped').removeClass('playing paused');
    return this.timelineProgress.css({
      'width': 0
    });
  };

  MediaPlayer_Controls.prototype.onSeek = function(perc) {
    this.timelineProgress.css({
      'width': perc * this.timelineContainer.width()
    });
    return this._changeTime(perc);
  };

  MediaPlayer_Controls.prototype.onMute = function(classOnly) {
    this.setVolume(0);
    return this.container.addClass('muted');
  };

  MediaPlayer_Controls.prototype.onUnMute = function(classOnly) {
    this.setVolume(1);
    return this.container.removeClass('muted');
  };

  MediaPlayer_Controls.prototype.hide = function() {
    if (this._areControlsHidden === false) {
      this.controls.addClass('hidden');
      return this._areControlsHidden = true;
    }
  };

  MediaPlayer_Controls.prototype.show = function() {
    if (this._areControlsHidden === true) {
      this.controls.removeClass('hidden');
      return this._areControlsHidden = false;
    }
  };

  MediaPlayer_Controls.prototype.setTotalTime = function(duration) {
    this.duration = duration;
    return this.timeTotal.text(this._sortTime(duration));
  };

  MediaPlayer_Controls.prototype.setVolume = function(val) {
    this.volumeProgress.css({
      'height': val * this.volumeContainer.height()
    });
    if (val === 0 && this._isMuted === false) {
      this._isMuted = true;
      return this.onMute();
    } else if (val > 0 && this._isMuted === true) {
      this._isMuted = false;
      return this.onUnMute();
    }
  };

  return MediaPlayer_Controls;

})();

MediaPlayer_Flash = (function() {
  function MediaPlayer_Flash(options) {
    this.progress = __bind(this.progress, this);
    this.canPlay = __bind(this.canPlay, this);
    this.timeupdate = __bind(this.timeupdate, this);
    this.setSrc = __bind(this.setSrc, this);
    this.seek = __bind(this.seek, this);
    this.setVolume = __bind(this.setVolume, this);
    this.getSrc = __bind(this.getSrc, this);
    this.getState = __bind(this.getState, this);
    this.getDuration = __bind(this.getDuration, this);
    this.getCurrentTime = __bind(this.getCurrentTime, this);
    this.unmute = __bind(this.unmute, this);
    this.mute = __bind(this.mute, this);
    this.stop = __bind(this.stop, this);
    this.pause = __bind(this.pause, this);
    this.play = __bind(this.play, this);
    var attributes, flashvars, params, playerId;
    this.container = options.container, this.src = options.src;
    playerId = this.container.attr('id');
    this.container.attr('id', '');
    params = {
      menu: 'false',
      allowFullscreen: 'true',
      allowScriptAccess: 'always',
      wmode: 'direct',
      bgcolor: '#000000'
    };
    attributes = {
      id: playerId,
      name: 'flashname',
      'class': 'anonVideo'
    };
    flashvars = {
      src: this.src,
      scope: playerId,
      url: 'test'
    };
    this.html = "<div class=\"video-container\">\n  <div id=\"" + playerId + "\"></div>\n</div>";
    this.videoContainer = $(this.html);
    this.container.html(this.videoContainer);
    swfobject.embedSWF("/swf/Main.swf?v=1", playerId, '100%', '100%', "10.0.0", "expressInstall.swf", flashvars, params, attributes, (function(_this) {
      return function() {
        _this.$video = _this.videoContainer.children();
        _this.video = _this.$video[0];
        return MediaPlayer_Proxy.get().addPlayer(_this);
      };
    })(this));
  }

  MediaPlayer_Flash.prototype.play = function() {
    if (this.video !== void 0) {
      return this.video.play();
    }
  };

  MediaPlayer_Flash.prototype.pause = function() {
    if (this.video) {
      return this.video.pause();
    }
  };

  MediaPlayer_Flash.prototype.stop = function() {
    this.video.currentTime = 0;
    return this.video.pause();
  };

  MediaPlayer_Flash.prototype.mute = function() {
    return this.video.volume = 0;
  };

  MediaPlayer_Flash.prototype.unmute = function() {
    return this.video.volume = this.volume;
  };

  MediaPlayer_Flash.prototype.getCurrentTime = function() {
    return this.currentTime;
  };

  MediaPlayer_Flash.prototype.getDuration = function() {
    return this.duration;
  };

  MediaPlayer_Flash.prototype.getState = function() {
    return null;
  };

  MediaPlayer_Flash.prototype.getSrc = function() {
    return this.video.src;
  };

  MediaPlayer_Flash.prototype.setVolume = function(val) {
    this.volume = val;
    return this.video.volume = val;
  };

  MediaPlayer_Flash.prototype.seek = function(val) {
    return this.video.currentTime = val;
  };

  MediaPlayer_Flash.prototype.setSrc = function(src) {
    var sources;
    if (src) {
      this.srcNoExt = src.substr(0, src.lastIndexOf("."));
    }
    sources = "<source src=\"" + this.srcNoExt + ".mp4\" type=\"video/mp4\" />";
    this.$video.html(sources);
    return this.video.load();
  };

  MediaPlayer_Flash.prototype.timeupdate = function(time) {
    return this.currentTime = time;
  };

  MediaPlayer_Flash.prototype.canPlay = function(duration) {
    this.duration = duration;
    return $(this).trigger(MediaPlayer.CANPLAY);
  };

  MediaPlayer_Flash.prototype.progress = function(progress) {
    return $(this).trigger(MediaPlayer.PROGRESS);
  };

  return MediaPlayer_Flash;

})();

MediaPlayer_HTML = (function() {
  function MediaPlayer_HTML(options) {
    this.setSrc = __bind(this.setSrc, this);
    this.seek = __bind(this.seek, this);
    this.setVolume = __bind(this.setVolume, this);
    this.getBuffer = __bind(this.getBuffer, this);
    this.getSrc = __bind(this.getSrc, this);
    this.getState = __bind(this.getState, this);
    this.getDuration = __bind(this.getDuration, this);
    this.getCurrentTime = __bind(this.getCurrentTime, this);
    this.unmute = __bind(this.unmute, this);
    this.mute = __bind(this.mute, this);
    this.stop = __bind(this.stop, this);
    this.pause = __bind(this.pause, this);
    this.play = __bind(this.play, this);
    this._onPause = __bind(this._onPause, this);
    this._onEnded = __bind(this._onEnded, this);
    this._onPlaying = __bind(this._onPlaying, this);
    this._onWaiting = __bind(this._onWaiting, this);
    this._onTimeUpdate = __bind(this._onTimeUpdate, this);
    this._onProgress = __bind(this._onProgress, this);
    this._onLoad = __bind(this._onLoad, this);
    this._onCanPlay = __bind(this._onCanPlay, this);
    this._initPlayer = __bind(this._initPlayer, this);
    this._initEvents = __bind(this._initEvents, this);
    this.container = options.container, this.src = options.src;
    if (!this.html) {
      this.html = "<div class=\"media-container\">\n  <audio>\n </audio>\n</div>";
    }
    this._initPlayer();
    this._initEvents();
  }

  MediaPlayer_HTML.prototype._initEvents = function() {
    return this.$media.on('load', this._onload).on('canplay', this._onCanPlay).on('progress', this._onProgress).on('timeupdate', this._onTimeUpdate).on('waiting', this._onWaiting).on('playing', this._onPlaying).on('ended', this._onEnded).on('pause', this._onPause);
  };

  MediaPlayer_HTML.prototype._initPlayer = function() {
    this.mediaContainer = $(this.html);
    this.$media = this.mediaContainer.children();
    this.media = this.$media[0];
    this.volume = 1;
    this.setSrc(this.src);
    return this.container.html(this.mediaContainer);
  };

  MediaPlayer_HTML.prototype._onCanPlay = function() {
    return $(this).trigger(MediaPlayer.CANPLAY);
  };

  MediaPlayer_HTML.prototype._onLoad = function() {};

  MediaPlayer_HTML.prototype._onProgress = function() {
    return $(this).trigger(MediaPlayer.PROGRESS);
  };

  MediaPlayer_HTML.prototype._onTimeUpdate = function() {
    return $(this).trigger(MediaPlayer.TIMEUPDATE);
  };

  MediaPlayer_HTML.prototype._onWaiting = function() {
    return $(this).trigger(MediaPlayer.WAITING);
  };

  MediaPlayer_HTML.prototype._onPlaying = function() {
    return $(this).trigger(MediaPlayer.PLAYING);
  };

  MediaPlayer_HTML.prototype._onEnded = function() {
    return $(this).trigger(MediaPlayer.ENDED);
  };

  MediaPlayer_HTML.prototype._onPause = function() {
    return $(this).trigger(MediaPlayer.PAUSED);
  };

  MediaPlayer_HTML.prototype.play = function() {
    return this.media.play();
  };

  MediaPlayer_HTML.prototype.pause = function() {
    return this.media.pause();
  };

  MediaPlayer_HTML.prototype.stop = function() {
    this.media.currentTime = 0;
    return this.media.pause();
  };

  MediaPlayer_HTML.prototype.mute = function() {
    return this.media.volume = 0;
  };

  MediaPlayer_HTML.prototype.unmute = function() {
    return this.media.volume = this.volume;
  };

  MediaPlayer_HTML.prototype.getCurrentTime = function() {
    return this.media.currentTime;
  };

  MediaPlayer_HTML.prototype.getDuration = function() {
    return this.media.duration;
  };

  MediaPlayer_HTML.prototype.getState = function() {
    return null;
  };

  MediaPlayer_HTML.prototype.getSrc = function() {
    return this.media.src;
  };

  MediaPlayer_HTML.prototype.getBuffer = function() {
    if (this.media.buffered.length === 1) {
      return this.media.buffered.end(0);
    } else {
      return 0;
    }
  };

  MediaPlayer_HTML.prototype.setVolume = function(val) {
    this.volume = val;
    return this.media.volume = val;
  };

  MediaPlayer_HTML.prototype.seek = function(val) {
    return this.media.currentTime = val;
  };

  MediaPlayer_HTML.prototype.setSrc = function(src) {
    var sources;
    if (src) {
      this.srcNoExt = src.substr(0, src.lastIndexOf("."));
    }
    sources = "<source src=\"" + this.srcNoExt + "\" type=\"media/mpeg\" />";
    this.$media.html(sources);
    return this.media.load();
  };

  return MediaPlayer_HTML;

})();

MediaPlayer_Proxy = (function() {
  var Proxy, instance;

  function MediaPlayer_Proxy() {}

  instance = null;

  Proxy = (function() {
    function Proxy() {
      this.getPlayer = __bind(this.getPlayer, this);
      this.addPlayer = __bind(this.addPlayer, this);
      this.players = {};
    }

    Proxy.prototype.addPlayer = function(player) {
      return this.players[player.video.id] = player;
    };

    Proxy.prototype.getPlayer = function(playerId) {
      return this.players[playerId];
    };

    return Proxy;

  })();

  MediaPlayer_Proxy.get = function(message) {
    return instance != null ? instance : instance = new Proxy(message);
  };

  MediaPlayer_Proxy.triggeredFromFlash = function(e, data) {
    var player;
    player = MediaPlayer_Proxy.get().getPlayer(data[0]);
    if (player[e]) {
      return player[e](data[1]);
    }
  };

  return MediaPlayer_Proxy;

})();

MediaPlayer_Templates = (function() {
  function MediaPlayer_Templates() {}

  MediaPlayer_Templates.view = "<div class=\"controls\">\n\n  <div class=\"timeline-container\">\n    <span class=\"background\"></span>\n    <span class=\"buffer\"></span>\n    <span class=\"progress\"></span>\n  </div>\n\n  <span class=\"play-button button\"><i></i></span>\n <span class=\"pause-button button\"><i></i></span>\n  <span class=\"volume-button button\"><i></i></span>\n <span class=\"fullscreen-button button\"><i></i></span>\n\n</div>\n";

  return MediaPlayer_Templates;

})();

MediaPlayer_Video = (function(_super) {
  __extends(MediaPlayer_Video, _super);

  function MediaPlayer_Video(options) {
    this.setSrc = __bind(this.setSrc, this);
    this.html = "<div class=\"media-container\">\n  <video>\n </video>\n</div>";
    MediaPlayer_Video.__super__.constructor.apply(this, arguments);
  }

  MediaPlayer_Video.prototype.setSrc = function(src) {
    var sources;
    if (src) {
      this.srcNoExt = src.substr(0, src.lastIndexOf("."));
    }
    sources = "<source src=\"" + this.srcNoExt + ".mp4\" type=\"video/mp4\">\n<source src=\"" + this.srcNoExt + ".webm\" type=\"video/webm\">";
    this.$media.html(sources);
    return this.media.load();
  };

  return MediaPlayer_Video;

})(MediaPlayer_HTML);

UI_Slider = (function() {
  UI_Slider.START = 'start';

  UI_Slider.CHANGE = 'change';

  UI_Slider.END = 'end';

  function UI_Slider(options) {
    this._onMouseUp = __bind(this._onMouseUp, this);
    this._onMouseMove = __bind(this._onMouseMove, this);
    this._onMouseDown = __bind(this._onMouseDown, this);
    this._initSlider = __bind(this._initSlider, this);
    this.container = options.container, this.startValue = options.startValue, this.type = options.type;
    this.startValue = this.startValue || 0;
    this._initPos = 0;
    this._pos = 0;
    this._movePos = 0;
    this._initSlider();
  }

  UI_Slider.prototype._initSlider = function() {
    return this.container.on(Event.MOUSEDOWN, this._onMouseDown);
  };

  UI_Slider.prototype._onMouseDown = function(event) {
    var e;
    e = event.type === 'touchstart' ? event.originalEvent.touches[0] : event;
    if (this.type === 'horizontal') {
      this._side = this.container.offset().left;
      this.containerSize = this.container.width();
      this.cursorPos = e.pageX - this._side;
      this.perc = this.cursorPos / this.containerSize;
    } else {
      this._side = this.container.offset().top;
      this.containerSize = this.container.height();
      this.cursorPos = e.pageY - this._side;
      this.perc = 1 - this.cursorPos / this.containerSize;
    }
    $(document).on(Event.MOUSEMOVE, this._onMouseMove).on(Event.MOUSEUP, this._onMouseUp);
    return $(this).trigger(UI_Slider.START);
  };

  UI_Slider.prototype._onMouseMove = function(event) {
    var e;
    e = event.type === 'touchmove' ? event.originalEvent.touches[0] : event;
    if (this.type === 'horizontal') {
      this.cursorPos = e.pageX - this._side;
      this.perc = this.cursorPos / this.containerSize;
    } else {
      this.cursorPos = e.pageY - this._side;
      this.perc = 1 - this.cursorPos / this.containerSize;
    }
    if (this.perc < 0) {
      this.perc = 0;
    } else if (this.perc > 1) {
      this.perc = 1;
    }
    return $(this).trigger(UI_Slider.CHANGE);
  };

  UI_Slider.prototype._onMouseUp = function() {
    $(document).off(Event.MOUSEMOVE, this._onMouseMove).off(Event.MOUSEUP, this._onMouseUp);
    return $(this).trigger(UI_Slider.END);
  };

  return UI_Slider;

})();
var Home = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#home' );
		this.heading = $( '.heading' );

		new LoaderHeading();

		interval = setInterval( function () {
			if ( that.heading.hasClass( "heading--is-loaded" ) ) {
				new Slider();
				clearInterval( interval );
			}
		}, 100 );

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );

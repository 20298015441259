/*
    TODO: Add current in navigation
*/

var Slider__who__values = Class.extend({

    init: function( options ) {


        var that = this;

        this.slider = $('body').find('.slider');
        this.slides = this.slider.find('.slider__slides');
        this.slide = this.slider.find('.slider__slides__slide');
        this.leftControls = this.slider.find('.slider__values__change--prev');
        this.rightControls = this.slider.find('.slider__values__change--next');
        this.interval;

        this.slideLength = this.slide.length;
        this.slideCloned;

        this.iFlag = 0;
        this.isAnim = false;

        if ( this.slideLength > 1 ) {
            this._initEvents();
        } else {
            this.leftControls.fadeOut();
            this.rightControls.fadeOut();
            this.slide.addClass('slider__slides__slide--current');
            this._onResize();
            W.window.on(Event.RESIZE, $.proxy(this._onResize, this));
        }
    },

    _initEvents: function() {
        var that = this;

        //this._createNavigation();
        this._cloneSLide();
        this._onResize();
        this._goToSlide(this.iFlag);

        // HAMMER JS

        var sliderElmt = document.getElementById( "slider__values" );
        var mc = new Hammer( sliderElmt );

        // listen to events...
        mc.on("swipeleft swiperight", function(ev) {
            if ( ev.type == "swipeleft" ) {
                if ( !that.isAnim ) {
                    that.isAnim = true;
                    that._goToNext();
                }
            } else {
                if ( !that.isAnim ) {
                    that.isAnim = true;
                    that._goToPrev();
                }
            }
        });



        this.leftControls.on(Event.CLICK, function( e, i ) {
            e.preventDefault();
            e.stopPropagation();

            if ( !that.isAnim ) {
                that.isAnim = true;
                that._goToPrev();
            }
        });

        this.rightControls.on(Event.CLICK, function( e, i ) {
            e.preventDefault();
            e.stopPropagation();

            if ( !that.isAnim ) {
                that.isAnim = true;
                that._goToNext();
            }
        });

        W.window.on(Event.RESIZE, $.proxy(this._onResize, this));
    },

    _autoSlide: function() {
        var that = this;

        this.interval = setInterval(function() {

            that._goToNext();

        }, 3000);
    },

    _goToNext: function() {
        var that = this;

        if ( that.iFlag >= that.slideLength - 1 ) {

            this.slides.addClass('no-transition');

            setTimeout(function(){
                that.slides.css( 'left', that.slide.outerWidth(true) );

                setTimeout(function(){
                    that.slides.removeClass('no-transition');
                }, 20);

            }, 100);

            setTimeout(function(){
                that.iFlag = 0;

                that._goToSlide(that.iFlag);
            }, 150);

        } else {
            that.iFlag++;
            //console.log(this.slide[this.iFlag]);

            that._goToSlide(that.iFlag);
        }
    },

    _goToPrev: function() {
        var that = this;

        if ( that.iFlag <= 0 ) {


            this.slides.addClass('no-transition');

            var left =  -((this.slide.outerWidth(true) * this.slideLength));

            setTimeout(function(){
                that.slides.css('left', left);

                setTimeout(function(){
                    that.slides.removeClass('no-transition');
                }, 20);

            }, 100);

            setTimeout(function(){
                that.iFlag = that.slideLength - 1;
                that._goToSlide(that.iFlag);
            }, 150);


        } else {
            that.iFlag--;
            that._goToSlide(that.iFlag);
        }
    },

    _cloneSLide: function() {
        var that = this;

        that.slide.eq(0).clone().appendTo(that.slides);
        that.slide.eq(1).clone().appendTo(that.slides);
        that.slide.eq(that.slideLength - 1).clone().prependTo(that.slides);
        that.slide.eq(that.slideLength - 2).clone().prependTo(that.slides);

        that.slideCloned = that.slider.find('.slide');

    },

    _goToSlide: function( i ) {
        var that = this;

        var active = that.slide.filter(".slider__slides__slide--current"),
            selected = $(that.slide[ i ]);

        that.slide.removeClass('slider__slides__slide--current');
        selected.addClass('slider__slides__slide--current');

        setTimeout(function() {
            that.isAnim = false;
            clearTimeout(that.interval);
            //that._autoSlide();
        }, 250);

        this.slides.css({
            'left': -i * W.ww,
        });

        // Change height based on current slide
        this.slider.css( 'height', $('.slider__slides__slide--current').height() );

    },

    _onResize: function() {
        var that = this;

        if ( W.ww <= 1024 ) {
            this.slider.css( 'height', $('.slider__slides__slide--current').height() );
        }

        this.slider.find('.slider__slides__slide').css({
            "width": W.ww
        });

        var translateLauch = ((2 * - W.ww));

        this.slides.css({
            "width": ((this.slide.length + 4) * W.ww),
            'left': -this.iFlag * W.ww,
            "transform":"translate(" + translateLauch + "px" + ",0)"
        });

    }

});
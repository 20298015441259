var Header = Class.extend( {

	init: function () {

		this.header = $( ".header" );
		this.content = $( ".content" );
		this.page = this.content.attr( "id" );

		this.nav = this.header.find( ".header__navigation" );
		this.navSecondary = this.header.find( ".header__secondary-nav" );

		this.navItem = this.header.find( ".menu-item" );
		this.pageID = this.content.attr( "id" );
		this.langSwitcherBtn = this.header.find( ".header__lang-btn" );
		this.langSwitcher = this.header.find( ".lang-switcher" );

		this.intranet = $( '.sidebar__lang' );
		this.intranetSwitcher = this.intranet.find( '.sidebar__lang__btn' );

		this._initEvents();
		this.animateNav();
		this.resize();
	},

	animateNav: function () {

		var that = this;

		// DOM Cache
		/* ******************************************************************************** */
		var headerLogo = this.header.find( ".header__logo" ),
			navItem = this.nav.find( "li" ),
			btnIntranet = this.header.find( ".header__btn" ),
			hambmenu = this.header.find( "#nav__hambmenu" ),
			navSecondaryItem = this.navSecondary.find( ".menu-item" ),
			langSwitcher = this.header.find( ".lang-switcher-wrapper" ),
			band = this.header.find( ".header__block" ),
			heading = this.content.find( ".page-heading" ),
			headingText = this.content.find( ".page-heading__text-group" ),
			headingOverlay = this.content.find( ".page-heading__overlay" ),
			content = this.content.find( ".content__sections" ),
			homeIntroBlocks = content.find( ".who" ).find( ".section-half" ).first().find( ".section-half__item" );

		// Animations variables
		/* ******************************************************************************** */
		var mainTl,
			itemEasing = Elastic.easeOut.config( 1, 1 ),
			itemFrom = {
				y: "108%",
				autoAlpha: 0,
				ease: itemEasing
			},
			itemDuration = 0.5,
			itemDelay = 0.1,
			backgroundDuration = 0.7,
			bandDuration = 1.2,
			itemPosition = "-=" + ( itemDuration - itemDelay );

		// The header is a special case and has different classes
		if ( this.page === "home" ) {
			heading = this.content.find( ".heading" );
			headingText = this.content.find( ".slider" );
		}

		// Animatate the opacity of the heading background and the page content
		/* ******************************************************************************** */
		var _backgroundsAnimation = ( function ( that ) {
			var tl = new TimelineMax();

			if ( heading.length > 0 ) {
				tl.fromTo( [ heading, content ], backgroundDuration, {
					opacity: 0,
					ease: Expo.easeOut
				}, {
					opacity: 1
				} );
			} else {
				tl.fromTo( [ content ], backgroundDuration, {
					opacity: 0,
					ease: Expo.easeOut
				}, {
					opacity: 1
				} );
			}
			return tl;
		} )( this );

		// Animate the white band of the main navigation
		/* ******************************************************************************** */
		var _bandAnimation = ( function ( that ) {
			return new TimelineMax().from( band, bandDuration, {
				autoAlpha: 0,
				scaleX: 0,
				transformOrigin: "0% 50%",
				ease: Expo.easeOut
			} );
		} )( this );

		// Animate each elements of the header, such as nav items, buttons, ...
		/* ******************************************************************************** */
		var _navsAnimation = ( function ( that ) {
			var tl = new TimelineMax();

			tl
				.from( headerLogo, itemDuration, itemFrom )
				.add( "nav start" )
				.staggerFrom( navItem, itemDuration, itemFrom, itemDelay, itemPosition )
				.fromTo( btnIntranet, itemDuration, itemFrom, {
					autoAlpha: 1,
					y: "-50%"
				}, itemPosition )
				.fromTo( hambmenu, itemDuration, itemFrom, {
					autoAlpha: 1,
					y: "-50%"
				}, itemPosition )
				.staggerFrom( navSecondaryItem, itemDuration, itemFrom, itemDelay, "nav start" )
				.fromTo( langSwitcher, itemDuration, itemFrom, {
					autoAlpha: 1,
					y: "0%"
				}, itemPosition );

			return tl;
		} )( this );

		// Animate the content of the page
		/* ******************************************************************************** */
		var _contentAnimation = ( function ( that ) {
			var tl = new TimelineMax(),
				offset = 15,
				headerDuration = _navsAnimation.totalDuration();

			tl.add( "start" );

			// In the home page, two blocks are animated with a delay
			if ( that.page === "home" ) {

				tl
					.fromTo( headingText, headerDuration, {
						x: -( offset ),
						autoAlpha: 0,
						ease: Expo.easeOut
					}, {
						x: 0,
						autoAlpha: 1
					}, "start" )

					.staggerFrom( homeIntroBlocks, headerDuration, {
						y: 200,
						ease: Elastic.easeOut.config( 1, 0.8 )
					}, 0.1, "start" );

			} else {
				tl
					.to( headingOverlay, headerDuration, {
						autoAlpha: 0
					} )
					.fromTo( headingText, headerDuration, {
						x: -( offset ),
						autoAlpha: 0,
						ease: Expo.easeOut
					}, {
						x: 0,
						autoAlpha: 1
					}, "start" )

					.fromTo( content, headerDuration, {
						x: ( offset ),
						autoAlpha: 0,
						ease: Expo.easeOut
					}, {
						x: 0,
						autoAlpha: 1
					}, "start" );
			}

			return tl;
		} )( this );

		// The main timeline containing all animations
		/* ******************************************************************************** */
		if ( that.navSecondary.length > 0 && langSwitcher.length > 0 && navSecondaryItem.length > 0 ) {
			mainTl = new TimelineMax( {
				delay: 1
			} );
			mainTl
				.set( [ navSecondaryItem, langSwitcher ], {
					autoAlpha: 0
				} )
				.set( that.navSecondary, {
					autoAlpha: 1
				} )
				.set( that.header, {
					autoAlpha: 1
				} )
				.add( "start" )
				.add( _backgroundsAnimation, "start" )
				.add( "bg" )
				.add( _bandAnimation, "bg-=0.3" )
				.add( _navsAnimation, "bg-=0.3" )
				.add( _contentAnimation, "bg-=0.3" )
				.set( band, {
					clearProps: "scaleX"
				} );
		}
	},

	_initEvents: function () {
		var that = this,
			timeout, lastHeaderElement = $( ".header__btn" );

		this.langSwitcherBtn.on( "click", this._openSwitcher.bind( this ) );

		this.intranetSwitcher.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			$( '.sidebar__lang__switcher' ).toggleClass( 'is-active' );
		} );

		this.navItem.on( {
			"mouseover": function () {
				$( this ).siblings().addClass( "is-not-hover" );
			},
			"mouseleave": function () {
				$( this ).siblings().removeClass( "is-not-hover" );
			}
		} );

		timeout = setTimeout( function () {
			that.header.addClass( "is-animated" );
			clearTimeout( timeout );
		}, 300 );

		$( "#nav__hambmenu" ).click( function () {
			$( 'body' ).toggleClass( 'menuOpen' );
		} );

		$( window ).keydown( function ( e ) {
			if ( e.keyCode == 27 && $( 'body' ).hasClass( 'menuOpen' ) ) {
				$( 'body' ).toggleClass( 'menuOpen' );
			}
		} );

		$( ".container__maskActiveMenu" ).click( function () {
			$( 'body' ).toggleClass( 'menuOpen' );
		} );

	},

	_openSwitcher: function ( e ) {
		e.preventDefault();
		this.langSwitcher.toggleClass( "is-active" );
	},

	resize: function () {
		var that = this;
		// Set lang switcher to static on small screen
		if ( $( '.sidebar__lang' ).length > 0 && $( '.sidebar__lang' ).offset().top <= ( $( '.sidebar__body' ).offset().top + $( '.sidebar__body' ).height() ) + 48 ) {
			$( '.sidebar__lang' ).css( 'position', 'static' );
		}
	}

} );

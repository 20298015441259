var Faq = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#faq' );
		this.contentSection = this.container.find( ".content__sections" );
		this.sidebar = this.container.find( ".sidebar--faq" );
		this.categoryItem = this.container.find( '.sidebar--faq__item' );
		this.faqSection = this.container.find( '.faqs' );
		this.faqSectionActive = this.container.find( '.faqs.is-active' );
		this.faqSections = this.container.find( ".faqs-sections" );
		this.faqTitle = this.faqSection.find( '.faqs__title' );
		this.itemFAQ = this.faqSection.find( '.faqs-list' ).find( ".faq" );
		this.isStickyFaq = false;
		this.currentSlug = "";

		this._initEvents();
		this._activeSection( this.categoryItem.eq( 0 ).attr( 'data-category' ) );
		this._checkGET();

	},

	_initEvents: function () {
		var that = this;

		this.container.on( Event.CLICK, '.sidebar--faq__item', function ( e ) {
			e.preventDefault();
			e.stopPropagation();
			var slug = $( this ).attr( 'data-category' );
			that._activeSection( slug );
			that._changeURL( slug );
		} );

		this.itemFAQ.on( Event.CLICK, function ( e ) {
			that.faq = $( this ).attr( 'data-post' );
			that.slug = $( this ).attr( 'data-cat' );

			that._openQuestion( that.faq );
			that._changeURL( that.slug, that.faq );
		} );


		// Sticky sidebar
		var sideBarSticky = that.sidebar.clone().prependTo( "#faq" ).addClass( "sidebar--faq--sticky" );
		var sideBarStatic = that.sidebar.addClass( "sidebar--faq--static" );

		sideBarSticky.css( {
			'right': W.ww - ( $( '.faqs.is-active' ).offset().left - 63 ),
			'top': $( '.faqs.is-active' ).offset().top - 20
		} );

		$( window ).resize( function () {
			if ( W.ww > 960 ) {
				sideBarSticky.css( {
					'right': W.ww - ( $( '.faqs.is-active' ).offset().left - 63 ),
					'top': $( '.faqs.is-active' ).offset().top - 20
				} );
			}

		} );

		$( window ).scroll( function ( e ) {
			if ( W.ww > 960 ) {
				if ( $( window ).scrollTop() >= $( '.faqs.is-active' ).offset().top - 20 ) {
					// If sidebar arives to the bottom -> don't scroll
					if ( $( window ).scrollTop() + sideBarSticky.height() >= $( '.faqs.is-active' ).offset().top + $( '.faqs.is-active' ).height() ) {
						sideBarSticky.css( {
							'position': 'absolute',
							'top': $( '.faqs.is-active' ).offset().top + $( '.faqs.is-active' ).height() - sideBarSticky.height()
						} );
					} else {
						that.isStickyFaq = true;
						sideBarSticky.addClass( "sidebar--faq--fixed" );
						sideBarSticky.css( {
							'position': 'fixed',
							'top': 20
						} );
					}
				} else {
					if ( that.isStickyFaq == true ) {
						sideBarSticky.css( {
							'position': 'absolute',
							'top': $( '.faqs.is-active' ).offset().top
						} );
					}
				}
			}
		} );

	},

	_checkGET: function () {
		var that = this;

		var query = that._getUrlVars();

		if ( query.length > 0 ) {

			if ( query[ 'slug' ] ) {
				that._activeSection( query[ 'slug' ] );
			}

			if ( query[ 'faq' ] ) {
				setTimeout( function () {
					that._openQuestion( query[ 'faq' ] );
				}, 1000 );
			}
		}
	},

	_changeURL: function ( slug, faq ) {
		var currentURL = window.location.pathname;

		var query = {
			slug: slug,
			faq: faq
		};

		var url = currentURL + '?' + $.param( query );

		if ( history.pushState ) {
			history.pushState( null, null, url );
		}
	},

	_activeSection: function ( slug ) {

		var that = this,
			tl = new TimelineMax( {
				paused: true,
			} );

		$( '.sidebar--faq__item' ).removeClass( 'current' );
		this.faqSection.removeClass( 'is-active' );

		for ( var i = 0, len = this.faqSection.length; i < len; i++ ) {
			var currentSection = that.faqSection.eq( i );
			if ( currentSection.hasClass( slug ) ) {
				tl
					.set( currentSection, {
						autoAlpha: 0
					} )
					.to( [ 'html, body' ], 1, {
						scrollTo: {
							y: that.contentSection.offset().top - 30
						},
						ease: Expo.easeOut
					} )
					.to( that.faqSection, 0.3, {
						autoAlpha: 0
					}, 0.2 )
					.to( currentSection, 0.3, {
						autoAlpha: 1
					}, 0.2 );
				tl.play();
				currentSection.addClass( 'is-active' );
				$( '.sidebar--faq__item' ).eq( i ).addClass( 'current' );
				this.categoryItem.eq( i ).addClass( 'current' );
				break;
			}
		}
	},

	_openQuestion: function ( slug ) {
		var that = this,
			title, elm, tl = new TimelineMax();
		for ( var i = 0, len = this.itemFAQ.length; i < len; i++ ) {
			elm = this.itemFAQ.eq( i );
			title = elm.attr( 'data-post' );
			if ( title === slug ) {
				elm.siblings().removeClass( "is-open" );
				elm.toggleClass( 'is-open' );
				tl.to( [ 'html, body' ], 1, {
					scrollTo: elm
				} );
				break;
			}
		}
	},

	_getUrlVars: function () {

		var vars = [],
			hash;
		var hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );

		for ( var i = 0; i < hashes.length; i++ ) {
			hash = hashes[ i ].split( '=' );
			vars.push( hash[ 0 ] );
			vars[ hash[ 0 ] ] = hash[ 1 ];
		}
		return vars;

	},

	destroy: function () {
		$( window ).unbind( 'scroll' );
	}

} );

var LoaderHeading = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".heading" );
		this.header = $( ".header" );
		this.url = this.container.find( '[data-img]' ).eq( 0 );

		if ( this.url.length > 0 ) {
			this._initEvents();
		}

	},

	_initEvents: function () {
		var that = this,
			interval;

		$( '<img/>' ).attr( 'src', this.url.attr( 'data-img' ) ).load( function () {

			interval = setInterval( function () {

				if ( that.header.hasClass( "is-animated" ) ) {

					that.container.addClass( 'heading--is-loaded' );

					clearInterval( interval );

				}
			}, 100 );


		} );
	}
} );

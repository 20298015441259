var Footer = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '.footer' );
		this.back = $( '.backtotop' );
		this.documentHeight = $( document ).height();

		this._initEvents();

	},

	_initEvents: function () {

		// TODO: Remove it.
		if( $('.cookie-message') ) {
			$('.cookie-message').cookieBar({
				closeButton: '.cookie-message__close'
			});
		}

		this.back.on( Event.CLICK, function ( e ) {
			e.stopPropagation();
			e.preventDefault();

			$( 'html,body' ).animate( {
				scrollTop: $( '.content' ).offset().top - 30
			}, 500 );
		} );

		W.window.on( Event.SCROLL, $.proxy( this._onMouseWheel, this ) );
	},

	_onMouseWheel: function () {
		var that = this;

		this.scrollTop = $( window ).scrollTop();

		if ( this.documentHeight > 800 && this.scrollTop > 800 ) {
			this.back.fadeIn();
		} else {
			this.back.fadeOut();
		}

	},


	resize: function () {

	}

} );

var Error404 = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#error404' );
		this.contentSection = this.container.find( ".content__sections" );

		$('footer').hide();
		$('header').hide();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;
	},

	destroy: function () {
		$('footer').show();
		$('header').show();
	}

} );

W = (function() {
    	function W() {}

	W.init = function() {
		W.window = $(window);
		W.body = $('body');
		W.ww = W.window.width();
		W.wh = $(window).height();
		W.sw = screen.width;
		W.sh = screen.height;
		W.scrollTop = {
			real: 0,
			calc: 0
		};

		return W.isTablet = $('body').hasClass('tablet') ? true : false;
	};

	return W;
})();
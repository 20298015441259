// Login Page

var Intranet = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#intranet' );
        this.loginForm = $( '#loginform-custom' );
        this.loginForm_username = $( '.login-username' );
        this.loginForm_password = $( '.login-password' );

		this._initEvents();
	},

	_initEvents: function () {
        var that = this;

        var label_username = this.loginForm_username.find( 'label' ).text().toLowerCase();
        var label_password = this.loginForm_password.find( 'label' ).text().toLowerCase();
        this.loginForm_username.find( 'input' ).attr( 'placeholder', label_username );
        this.loginForm_password.find( 'input' ).attr( 'placeholder', label_password );

        $('.login-remember input[type=checkbox]').change( function(){
            $(this).parent().toggleClass( 'checked' );
        } );

    },

	destroy: function () {

	}

} );

/*
    TODO: Add current in navigation
*/

var Slider = Class.extend({

    init: function( options ) {

        var that = this;

        this.slider = $('body').find('.slider');
        this.slides = this.slider.find('.slider__slides');
        this.slide = this.slider.find('.slider__slides__slide');
        this.leftControls = this.slider.find('.slider__navigation--left');
        this.rightControls = this.slider.find('.slider__navigation--right');
        this.navigation = this.slider.find('.slider__dots');
        this.navigationItem = this.navigation.find('.slider__dots__item');
        this.interval;

        this.slideLength = this.slide.length;
        this.slideCloned;

        this.iFlag = 0;
        this.isAnim = false;

        if ( this.slideLength > 1 ) {
            this._initEvents();
        } else {
            this.leftControls.fadeOut();
            this.rightControls.fadeOut();
            this.slide.addClass('slider__slides__slide--current');
            this._onResize();
            W.window.on(Event.RESIZE, $.proxy(this._onResize, this));
        }
    },

    _initEvents: function() {
        var that = this;

        this._onResize();
        this._createNavigation();
        //this._cloneSLide();
        this._goToSlide(this.iFlag);

        // HMMER JS

        var sliderElmt = document.getElementById( "slider__home" );
        var mc = new Hammer( sliderElmt );

        // listen to events...
        mc.on("swipeleft swiperight", function(ev) {
            if ( ev.type == "swipeleft" ) {
                that._goToNext();
            } else {
                that._goToPrev();
            }
        });

        this.leftControls.on(Event.CLICK, function( e, i ) {
            e.preventDefault();
            e.stopPropagation();

            if ( !that.isAnim ) {
                that.isAnim = true;
                that._goToPrev();
            }
        });

        this.rightControls.on(Event.CLICK, function( e, i ) {
            e.preventDefault();
            e.stopPropagation();

            if ( !that.isAnim ) {
                that.isAnim = true;
                that._goToNext();
            }
        });

        this.navigationItem.on(Event.CLICK, function( e ) {
            e.preventDefault();
            e.stopPropagation();

            var index = that.navigationItem.index($(this));

            that._goToSlide(index);
        });

        W.window.on(Event.RESIZE, $.proxy(this._onResize, this));
    },

    _createNavigation: function() {
        var that = this;
        for ( var i = 0; i < this.slide.length; i++ ) {
            this.navigation.append('<li class="slider__dots__item"></li>');
        }

        this.navigationItem = this.navigation.find('.slider__dots__item');
    },

    _autoSlide: function() {
        var that = this;

        this.interval = setInterval(function() {

            that._goToNext();

        }, 5000);
    },

    _goToNext: function() {
        var that = this;

        if ( that.iFlag >= that.slideLength - 1 ) {

            that.iFlag = 0;
            that._goToSlide(that.iFlag);

        } else {
            that.iFlag++;
            that._goToSlide(that.iFlag);
        }
    },

    _goToPrev: function() {
        var that = this;

        if ( that.iFlag <= 0 ) {


            that.iFlag = that.slideLength - 1;
            that._goToSlide(that.iFlag);


        } else {
            that.iFlag--;
            that._goToSlide(that.iFlag);
        }
    },

    _cloneSLide: function() {
        var that = this;

        that.slide.eq(0).clone().appendTo(that.slides);
        that.slide.eq(1).clone().appendTo(that.slides);
        that.slide.eq(that.slideLength - 1).clone().prependTo(that.slides);
        that.slide.eq(that.slideLength - 2).clone().prependTo(that.slides);

        that.slideCloned = that.slider.find('.slide');

    },

    _goToSlide: function( i ) {
        var that = this;

        var active = that.slide.filter(".slider__slides__slide--current"),
            selected = $(that.slide[ i ]);

        that.slide.removeClass('slider__slides__slide--current');
        selected.addClass('slider__slides__slide--current');


        that.navigationItem.removeClass('is-active');
        $(that.navigationItem[ i ]).addClass('is-active');

        setTimeout(function() {
            that.isAnim = false;
            clearTimeout(that.interval);
            that._autoSlide();
        }, 250)

    },

    _onResize: function() {
        /*var that = this;

        if ( this.slider.parent().hasClass( 'heading' ) && !this.slider.parent().hasClass( 'heading--404' ) ) {
            if ( W.ww > 600 ) {
                that.slider.css( {
                    "height": W.wh * 0.8
                } );
            } else {
                that.slider.css( {
                    "height": W.wh * 0.95
                } );
            }
        }*/
    }

});
var Jobs = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#jobs' );
		this.contentSection = this.container.find( ".content__sections" );

		new NinjaFormAjax();

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;
		$( 'input[type="file"]' ).ezdz( {
			text: terms.drop
		} );
	},

	destroy: function () {

	}

} );

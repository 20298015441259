var Account = Class.extend( {

	init: function () {

		var that = this;

		this.container = $( '#my_account' );
		this.user_display_name = $( '#user_display_name' );

		this.addRequest = this.container.find( '.user__update__form' );

		this.url = api_user_account + '/' + current_user_id;

		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.addRequest.submit(function( e ) {

			var elmts = {};

			$.each( $(this).serializeArray(), function( index, value ) {
				elmts[value.name] = value.value;
			} );

			that._sentRequest( JSON.stringify( elmts ) );
			e.preventDefault();
			e.stopPropagation();
		});
	},

	_sentRequest: function ( datas ) {
		var that = this;

		$.ajax( {
			type: 'POST',
			url: this.url,
			data: {datas:datas},
			success: function ( response ) {

				// 1. Clear error messages and classes
				$('.form-field').removeClass( 'error' );
				$( '.error__message, .success__message' ).fadeOut( function(){
					$( this ).remove();
				} );

				// 2. Check if success or errors
				if( Array.isArray(response) ) {
					// 3. Add new error messages and classes
					response[0].forEach( function(element, index) {
						$( '.message__container' ).append( '<p class="error__message"><i class="icon icon-close"></i>' + element + '</p>' ).hide().delay( 400 ).fadeIn();
					});
					response[1].forEach( function(element, index) {
						$('.user__'+element).addClass('error');
					});
				} else {
					$( '.message__container' ).append( '<p class="success__message"><i class="icon icon-checkbox"></i>' + terms.update + '</p>' ).hide().delay( 400 ).fadeIn();

					// Change the display name of the user without reloading page
					that.user_display_name.text( $( '#last-name' ).val() );
				}

			},
			error: function ( status ) {}
		} );

	},

	resize: function () {
		var that = this;
	},

	destroy: function () {

	}

} );;

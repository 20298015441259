var Contact = Class.extend({

    init: function() {

        var that = this;

        this.container = $('#contact');
        this.footer = $(".footer");
        this.btnContact = this.footer.find(".btn-contact");
        new NinjaFormAjax();
        new gMaps();

        this._initEvents();
    },

    _initEvents: function() {
        var that = this,
            bodyHtml = $("body, html");

        this.btnContact.on("click", function(e) {
            e.preventDefault();
            bodyHtml.animate({
                scrollTop: 0
            }, 500, $.bez([ 0.455, 0.030, 0.515, 0.955 ]));
        });

    },

    resize: function() {
        var that = this;
    },

    destroy: function() {

    }

});

var TransitionsFactory;

TransitionsFactory = (function(_super) {
  __extends(TransitionsFactory, _super);

  function TransitionsFactory() {
    this._transitionOut = __bind(this._transitionOut, this);
    TransitionsFactory.__super__.constructor.apply(this, arguments);
    this._transitionInDelay = 1100;
  }

  TransitionsFactory.prototype._transitionOut = function() {
    return TransitionsFactory.__super__._transitionOut.apply(this, arguments);
  };

  TransitionsFactory.prototype._defaultIn = function() {
    return TransitionsFactory.__super__._defaultIn.apply(this, arguments);
  };

  return TransitionsFactory;

})(Transitions);

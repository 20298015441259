var Popup = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( 'body' );
		this.popup = this.container.find( '.popup' );
		this.cache = [];
		this.link = this.container.find( '.listing-team a' );
		this.btn = this.container.find( '.heading__button' );
		this.btnVideo = this.container.find( '.btn--video' );

		console.log( this.btnVideo );

		that.isLoad = false;
		this._initEvents();
	},

	_initEvents: function () {
		var that = this;

		this.link.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			that.isLoad = true;

			var url = $( this ).attr( 'href' );
			var params = {
				'member': $( this ).attr( 'data-slug' )
			};

			that._loadData( url, params );
		} );

		this.btn.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();
			var url = $( this ).attr( 'href' ).split( '/' );

			that.isLoad = true;

			that._setVimeoPlayer( url[ 3 ] );
		} );

		this.btnVideo.on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();

			var id = $( this ).attr( 'data-uri' );
			that.isLoad = true;

			that._setVimeoPlayer( id );
		} );

		$( document ).keyup( function ( e ) {
			if ( e.keyCode == 27 ) { // escape key maps to keycode `27`
				that._closePopup();
			}
		} );

		this.container.find( '.popup__cache' ).on( Event.CLICK, function ( e ) {
			e.preventDefault();
			e.stopPropagation();
			that._closePopup();
		} );

		if ( this._getParams( 'member' ) ) {
			this.link.each( function () {
				if ( $( this ).attr( 'data-slug' ) === that._getParams( 'member' ) ) {
					var url = $( this ).attr( 'href' );
					var params = {
						'member': $( this ).attr( 'data-slug' )
					};

					that._loadData( url, params );
				}
			} );
		}

	},

	_setVimeoPlayer: function ( id ) {

		var vimeo = '<div class="popup__inner__video"><iframe src="https://player.vimeo.com/video/' + id + '" width="1920" height="1080" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe></div>';

		this.popup.find( '.popup__inner' ).addClass( 'popup__inner--overflow' );

		this._setData( vimeo );
	},

	_closePopup: function () {
		this.popup.removeClass( 'popup--is-open' );
		this.popup.find( '.popup__inner__video' ).remove();
		this.popup.find( '.popup__inner' ).removeClass( 'popup__inner--overflow' );
		this._changeURL( '' );
	},

	_initCache: function ( url, data ) {
		var that = this;

		this.cache[ url ] = data;
	},

	_changeURL: function ( params ) {

		var currentURL = window.location.pathname;

		var url = "";

		if ( params !== "" ) {
			url = currentURL + '?' + params;
		} else {
			url = currentURL;
		}

		if ( history.pushState ) {
			history.pushState( null, null, url );
		}

		return url;
	},

	_loadData: function ( url, params ) {
		var that = this;

		if ( this.cache[ url ] ) {
			that._setData( this.cache[ url ] );
			that._changeURL( $.param( params, true ) );
		} else {

			$.ajax( {
				url: url,
				dataType: "html",
				type: 'GET',
				context: document.body,
				success: function ( data, statut ) {
					var response = $( data );
					var profile = response.find( '.section-content--team' );

					that._initCache( url, profile );
					that._setData( profile );
					that._changeURL( $.param( params, true ) );
				}
			} );

		}
	},

	_setData: function ( data ) {
		this.popup.find( '.popup__inner' ).html( data );
		new EmailSpam();
		this.popup.addClass( 'popup--is-open' );
		this.isLoad = false;
	},

	_getParams: function ( param ) {
		var vars = {};
		window.location.href.replace( location.hash, '' ).replace(
			/[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
			function ( m, key, value ) { // callback
				vars[ key ] = value !== undefined ? value : '';
			}
		);

		if ( param ) {
			return vars[ param ] ? vars[ param ] : null;
		}
		return vars;
	}

} );

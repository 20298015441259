var oEmbed = Class.extend( {
	init: function () {
		var that = this;

		this.container = $( ".content" );
		this.video = this.container.find( '.video-ratio iframe' );

		this._initEvents();
		this._onResize();
	},

	_initEvents: function () {
		this.video.each( function () {
			$( this ).attr( 'data-ratio', $( this ).height() / $( this ).width() );
			$( this ).removeAttr( 'width' );
			$( this ).removeAttr( 'height' );
		} );

		W.window.on( Event.RESIZE, $.proxy( this._onResize, this ) );
	},

	_onResize: function () {
		var that = this;

		this.video.each( function () {
			$( this ).width( $( this ).parent().width() )
			$( this ).height( $( this ).parent().width() * $( this ).attr( 'data-ratio' ) );
		} );
	}
} );

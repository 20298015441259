Utils = (function() {
  	function Utils() {}

  	Utils.getCoverSizeImage = function(picWidth, picHeight, containerWidth, containerHeight) {
    		var ch, cr, cw, ph, pr, pw;
	    	pw = picWidth;
	    	ph = picHeight;
	    	cw = containerWidth || W.ww;
	    	ch = containerHeight || W.wh;
	    	pr = pw / ph;
	    	cr = cw / ch;

    		if (cr < pr) {
      		return {
        			'width': ch * pr,
        			'height': ch,
        			'top': 0,
        			'left': -((ch * pr) - cw) * 0.5
      		};
    		} else {
      		return {
        			'width': cw,
        			'height': cw / pr,
        			'top': -((cw / pr) - ch) * 0.5,
        			'left': 0
      		};
    		}
  	};

  	Utils.clearTimers = function(timers) {
    		return $.each(timers, function(key, timer) {
      	return clearTimeout(timer);
    	});
};

Utils.playSound = function(file, looping, volume) {
	var audio;
    	audio = new Audio(file);

    	if (looping) {
	      $(audio).on('ended', function() {
	        audio.currentTime = 0;
	        return audio.play();
	      });
    	}

    	if (volume) {
      	audio.volume = volume;
    	}
    	audio.play();
    	return audio;
  };

  return Utils;

})();
var Calculator = Class.extend({

    init: function() {

        var that = this;

        this.rooms = [];
        this.occupant = 0;
        this.area = 0;
        this.secondesPerSquareMeter = 40;


        this.container = $('#calculator');
        this.btnAddRoom = this.container.find(".btn-add-room");
        this.tagsContainer = this.container.find(".room-list");
        this.result = this.container.find(".result");

        this.inputRoomSelected = this.container.find(".select-room");
        this.inputRoomQt = this.container.find(".input-room-qt");
        this.inputPetsCheckboxYes = this.container.find(".input-pets-yes");
        this.inputPetsCheckboxNo = this.container.find(".input-pets-yes");
        this.inputOccupants = this.container.find(".input-occupant");
        this.inputArea = this.container.find(".input-area");
        this.inputControllers = this.container.find(".input__controller-num");

        this._initEvents();
        this._calcTime();
    },

    _initEvents: function() {
        var that = this,
            bodyHtml = $("body, html");

        this.rooms.push();

        this.btnAddRoom.on("click", this._addTag.bind(this));
        this.tagsContainer.on("click", ".tag__remove", this._deleteTag.bind(this));

        this.inputArea.on("change keyup", this._calcTime.bind(this));
        this.inputOccupants.on("change keyup", this._calcTime.bind(this));
        this.inputPetsCheckboxYes.on("change", this._calcTime.bind(this));
        this.inputPetsCheckboxNo.on("change", this._calcTime.bind(this));

        this.inputControllers.on("click", this._controllInputValue.bind(this));

    },

    _controllInputValue: function( e ) {

        e.preventDefault();

        var controller = $(e.currentTarget),
            parent = controller.parent(),
            input = parent.find("input"),
            value = parseInt(input.val()),
            newVal,
            tl = new TimelineMax();

        tl.to(controller, 0.1, { scale: 0.6, ease: Expo.easeOut });
        tl.to(controller, 0.2, { scale: 1, ease: Elastic.easeOut.config(1, 0.7) });

        if ( controller.hasClass("minus") && value >= 2 ) {
            value--;
        }

        if ( controller.hasClass("plus") ) {
            value++;
        }

        input.val(value);

        this._calcTime();


    },

    _calcTime: function( e ) {

        if ( e ) {
            e.preventDefault();
        }

        if ( this.rooms.length <= 0 ) {
            this.result.text("");
            return false;
        }

        var occupant = parseInt(this.inputOccupants.val()),
            area = parseInt(this.inputArea.val()),
            totalRoomTime = 0,
            areaTimeSec = 0,
            occupantTime = 0,
            totalTimeSec = 0,
            totalTimeHour = 0,
            petsTime = 0;

        for ( var i = 0, l = this.rooms.length; i < l; i++ ) {
            totalRoomTime += ( this.rooms[ i ].qt * this.rooms[ i ].timePerRoom );
        }


        occupantTime = occupant > 2 ? 10 : 0;

        petsTime = this.inputPetsCheckboxYes.is(':checked') ? 10 : 0;

        areaTimeSec = parseInt(this.inputArea.val()) * 40;

        totalTimeSec = this._minToSec(totalRoomTime) + this._minToSec(occupantTime) + this._minToSec(petsTime) + areaTimeSec;

        this.result.text(this._secToHours(totalTimeSec));

    },

    _secToHours: function( time ) {
        var reste = time;
        var result = '';

        var nbHours = Math.floor(reste / 3600);
        reste -= nbHours * 3600;

        var nbMinutes = Math.floor(reste / 60);
        reste -= nbMinutes * 60;

        var nbSeconds = reste;

        if ( nbHours > 3 ) {

            if ( nbMinutes >= 29 ) {
                nbHours++;
            }

            result = nbHours + 'h';

        }

        else if ( nbHours < 3 ) {
            result = '3h';
        }

        else {
            result = '3h';
        }

        return result;
    },

    _minToSec: function( minutes ) {
        return minutes * 60;
    },

    _addTag: function( e ) {

        if ( e ) {
            e.preventDefault();
        }

        var newRoom = {},
            roomQt = parseInt(this.inputRoomQt.val()),
            roomSelected = this.inputRoomSelected.find(":selected"),
            roomName = roomSelected.text(),
            roomSlug = roomSelected.data('slug'),
            roomTimePerRoom = this.inputRoomSelected.val(),
            roomExist = false;

        newRoom = {
            "slug": roomSlug,
            "name": roomName,
            "qt": roomQt,
            "timePerRoom": roomTimePerRoom
        };

        // Check if the room has already been added
        for ( var i = 0, l = this.rooms.length; i < l; i++ ) {
            if ( this.rooms[ i ].slug === newRoom.slug ) {
                this.rooms[ i ].qt = this.rooms[ i ].qt + newRoom.qt;
                roomExist = true;
                this._updateTag(this.rooms[ i ]);
                return;
            }
        }

        if ( !roomExist ) {
            this.rooms.push(newRoom);
            this._createNewTag(newRoom);
        }

    },

    _deleteTag: function( e ) {
        e.preventDefault();
        var tag = $(e.currentTarget).parent(),
            nextTags = tag.nextAll(),
            roomSlug = tag.data("slug"),
            tl = new TimelineMax({
                onComplete: function() {
                    TweenMax.set(nextTags, { clearProps: "y" });
                    tag.remove();
                }
            });

        tl
            .to(tag, 0.4, { autoAlpha: 0, x: "100%", ease: Expo.easeOut }, 0)
            .staggerTo(nextTags, 0.4, { y: "-76px", ease:  Elastic.easeOut.config(1, 0.9) }, 0.1, 0);

        for ( var i = 0, l = this.rooms.length; i < l; i++ ) {
            if ( this.rooms[ i ].slug === roomSlug ) {
                this.rooms.splice(i, 1);
                break;
            }
        }

        this._calcTime();

    },

    _updateTag: function( room ) {
        var tag = this.tagsContainer.find("." + room.slug),
            tagQt = tag.find(".tag__qt"),
            tl = new TimelineMax();

        tl.to(tagQt, 0.1, { scale: 0.6, ease: Expo.easeOut });
        tl.to(tagQt, 0.4, { scale: 1, ease: Elastic.easeOut.config(1, 0.3) });
        tagQt.text(room.qt);
        this._calcTime();

    },

    _createNewTag: function( room ) {
        var tag = $("<div></div>"),
            tagClose = $("<button></button>"),
            tagQt = $("<span></span>"),
            tagName = $("<span></span>");

        tag.attr({
            "class": "tag tag--room " + room.slug,
            "data-slug": room.slug
        });
        tagQt.attr("class", "tag__qt");
        tagClose.attr("class", "tag__remove icon icon-close");
        tagName.attr("class", "tag__name");

        tagName.text(room.name);
        tagQt.text(room.qt);

        console.log(tag.outerHeight(true));

        // Hide the tag first
        TweenMax.set(tag, { autoAlpha: 0, y: "76px" });

        tag.append(tagQt);
        tag.append(tagName);
        tag.append(tagClose);

        this.tagsContainer.append(tag);

        TweenMax.to(tag, 0.4, { autoAlpha: 1, y: 0, ease: Elastic.easeOut.config(1, 0.9) });

        this._calcTime();

    }

});

var How = Class.extend( {

	init: function () {

		var that = this,
			interval;

		this.container = $( '#how' );

		new CustomFAQLinks();

		this._initEvents();
	},

	_initEvents: function () {},

	destroy: function () {

	}

} );
